import React, { Component } from "react";
//, { Component } 
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from "react-router-dom";

import { fade, withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Pagination from '@material-ui/lab/Pagination';

import Box from '@material-ui/core/Box';
import API from "../utils/API";


import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
//import InputBase from '@material-ui/core/InputBase';


//import SearchIcon from '@material-ui/icons/Search';
//import Fab from '@material-ui/core/Fab';
import TextField from '@material-ui/core/TextField';

import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';

import Dialog from '@material-ui/core/Dialog';

import DialogContent from '@material-ui/core/DialogContent';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CloseIcon from '@material-ui/icons/Close';

import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import CircularProgress from '@material-ui/core/CircularProgress';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import Button from '@material-ui/core/Button';

import {store} from '../utils/store';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  paper: {
    margin: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  table: { },
  tableWrapper: {
    width:'100%',
    overflow: 'auto'
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  imageStyle: {
    width: '100px', height: '75px',
  },
  hide:{display:'none',},
});




class Products extends Component {
  //export default function Products() {

  constructor(props) {
    super(props);
    this.PageChanged = this.PageChanged.bind(this);
     
    
    if ( window.user_id===undefined || window.user_id===null)
    {
      window.location='/signin';
      
    }

    let user_id='';
    let company_id='';

    if(window.user_id)
    {
      user_id=window.user_id;
    }
   
    if(window.company_id)
    {
      company_id=window.company_id;
    }
 

    this.state = {
      url_addition : store.url_addition,
      company_id: company_id,
      user_id:user_id,
      title: "พนักงาน",
      dataset: 'users',
      mode: "add",
      open: false,
      waitting: false,
      rows: [],
      selectedItems: {},
      page: 1,
      pageCount: 1,
      search: "",
      categorys: [],
      units: [],
      Snackbar: false,
      message: "",
      user_idValidator: "",
      emailValidator: "",
      passwordValidator: "",
      firstNameValidator: "",
      menus: [{ TRN000: false }, { TRN001: false }, { TRN002: false }, { MST001: false }, { MST002: false }, { MST003: false },{ MST004: false }, { SETTINGS: false }],
    };
    this.fileInput = React.createRef();
    this.forms = React.createRef();

    this.refresh();
  }



  Snackbar = (message) => {
    this.setState({ Snackbar: true, message: message });
  };

  OpenDialog = () => {
    this.setState({ open: true });
  };

  CloseDialog = (value) => {
    this.setState({ open: false });
  };


  SearchChanged = (e) => {
    this.setState({ search: e.target.value });


    if(this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.refresh(1);
    }, 400);



  };

  PageChanged = (event, value) => {
    this.refresh(value);
  };

  lookupTable = () => {
    /* API.get('data/lookup?table=' + this.state.dataset + "&field=category")
       .then((res) => {
         let items = [];
 
         res.data.forEach(element => {
           items.push({ text: element });
         });
         this.setState({ categorys: items });
 
       }).catch((error) => {
         console.log(error)
       });*/

  };



  addnew = () => {
    this.ClearMenus();
    this.setState({ mode: "add" });
    this.setState({ waitting: true });


      let items = {
        user_id: "",
        company_id: this.state.company_id,
        create_by:this.state.user_id,
        update_by:this.state.user_id,
        email: "-",
        first_name: "",
        last_name: "-",
        password: "",
        menus: "",
        info: "",
        active: 'Y',
        admin_flag:'N',
        
      };

      this.setState({ selectedItems: items });
      this.lookupTable();
      this.OpenDialog();
      this.setState({ waitting: false });


  }



  ClearMenus = () => {
    //let items = [{ TRN000: false }, { TRN001: false }, { TRN002: false }, { MST001: false }, { MST002: false }, { MST003: false }, { SETTINGS: false }];
    const item = this.state.menus;
    item.TRN000 = true;
    item.TRN001 = true;
    item.TRN002 = true;
    item.MST001 = false;
    item.MST002 = false;
    item.MST003 = false;
    item.MST004 = false;
    item.SETTINGS = false;
    this.setState({ menus: item });
  }

  edit = (items) => {

    var selected = items.menus.split(',');
    const item = this.state.menus;

   
    this.ClearValidation();
    this.setState({ mode: "edit" });
    this.lookupTable();
  
    item.TRN000 = selected.indexOf('TRN000') !== -1 ? true : false;
    item.TRN001 = selected.indexOf('TRN001') !== -1 ? true : false;
    item.TRN002 = selected.indexOf('TRN002') !== -1 ? true : false;
    item.MST001 = selected.indexOf('MST001') !== -1 ? true : false;
    item.MST002 = selected.indexOf('MST002') !== -1 ? true : false;
    item.MST003 = selected.indexOf('MST003') !== -1 ? true : false;
    item.MST004 = selected.indexOf('MST004') !== -1 ? true : false;
    item.SETTINGS = selected.indexOf('SETTINGS') !== -1 ? true : false;
    this.setState({ selectedItems: items, menus: item });
    this.OpenDialog();

  }

  delete = (items) => {
    if(items.user_id==='admin')
    {
      alert('ไม่อนุญาตลบข้อมูล admin');
      return;
    }
    if (window.confirm('ท่านต้องการลบข้อมูล? ข้อมูล=' + items.user_id +  '@' + this.state.company_id)) {
      API.delete(this.state.url_addition  + this.state.dataset + '/' + items.user_id + '?company_id=' + this.state.company_id)
        .then((res) => {

          this.Snackbar('ลบข้อมูลแล้ว!');
          this.refresh(this.state.page);

        }).catch((error) => {

          alert(error);
          console.log(error)
        });
    }

  }

  ClearValidation = () => {
    this.setState({ emailValidator: "", passwordValidator: "", firstNameValidator: "" });
  }

  validation = () => {
    let err = '';
//required

if (this.state.selectedItems.user_id === '') {

  this.setState({ user_idValidator: "ต้องการระบุ และขนาดมากสุด 20 ตัว." });
  if (err !== '') { err += "," };
  err += "รหัส";
}
else {
  this.setState({ user_idValidator: "" });
}

    if (this.state.selectedItems.email === '') {

      this.setState({ emailValidator: "ต้องการระบุ และขนาดมากสุด 50 ตัว." });
      if (err !== '') { err += "," };
      err += "อีเมล์";
    }
    else {
      this.setState({ emailValidator: "" });
    }

    //passwordValidator: "",
    if (this.state.selectedItems.password === '') {

      this.setState({ passwordValidator: "ต้องการระบุ และขนาดมากสุด 50 ตัว." });
      if (err !== '') { err += "," };
      err += "รหัสผ่าน";
    }
    else {
      this.setState({ passwordValidator: "" });
    }
    //firstNameValidator: "",
    if (this.state.selectedItems.first_name === '') {

      this.setState({ firstNameValidator: "ต้องการระบุ และขนาดมากสุด 50 ตัว." });
      if (err !== '') { err += "," };
      err += "ชื่อ";
    }
    else {
      this.setState({ firstNameValidator: "" });
    }
    return err;
  }

  save = () => {
    var selectedmenus = '';
    const item = this.state.menus;
  
   


    if (item.TRN000) {
      if (selectedmenus !== '') { selectedmenus += ","; }
      selectedmenus += "TRN000";
    }
    if (item.TRN001) {
      if (selectedmenus !== '') { selectedmenus += ","; }
      selectedmenus += "TRN001";
    }
    if (item.TRN002) {
      if (selectedmenus !== '') { selectedmenus += ","; }
      selectedmenus += "TRN002";
    }
    if (item.MST001) {
      if (selectedmenus !== '') { selectedmenus += ","; }
      selectedmenus += "MST001";
    }
    if (item.MST002) {
      if (selectedmenus !== '') { selectedmenus += ","; }
      selectedmenus += "MST002";
    }
    if (item.MST003) {
      if (selectedmenus !== '') { selectedmenus += ","; }
      selectedmenus += "MST003";
    }
    if (item.MST004) {
      if (selectedmenus !== '') { selectedmenus += ","; }
      selectedmenus += "MST004";
    }
    if (item.SETTINGS) {
      if (selectedmenus !== '') { selectedmenus += ","; }
      selectedmenus += "SETTINGS";
    }

    if (this.state.mode === 'add' && this.state.selectedItems.user_id.toLowerCase()==='admin') {
  
      alert('ผู้ใช้งาน admin ไม่อนุญาตให้เพิ่มได้ เป็นรหัสล็อคไว้พิเศษ.');
    return;
    
  }
  
    if(this.state.selectedItems.user_id==='admin' )
    {
      if (!item.MST002) {
        alert('ผู้ใช้งาน admin จะต้องเลือก เมนูพนักงาน.');
      return;
      }

     
    }



    let err = this.validation();
     
    if (selectedmenus === '') {
      if (err !== '') { err += "," };
      err += "เลือกอย่างน้อยหนึ่งเมนู";
    }
    

    if (err !== '') {
      this.Snackbar('พบข้อผิดพลาด! ' + err);
    }
    else {

      this.setState({ waitting: true });
      const datas = this.state.selectedItems;
      datas.menus=selectedmenus;
      if (this.state.selectedItems.user_id.toLowerCase()!=='admin') {
        datas.admin_flag='N';
      }
      this.setState({ selectedItems: datas });

      if (this.state.mode === 'edit') {
         
        const item = this.state.selectedItems; 
        item.update_by =this.state.user_id ;
        

        API.put(this.state.url_addition + this.state.dataset  + '/' + this.state.selectedItems.user_id , this.state.selectedItems )
          .then((res) => {
            console.log(res)
            this.CloseDialog();
            this.setState({ waitting: false });
            if(res.data.error==null)
            {
                this.Snackbar(res.data.messages);
            }
           
          }).catch((error) => {
            this.setState({ waitting: false });
            alert(error);
            console.log(error)
          });
      }
      if (this.state.mode === 'add') {
        API.post(this.state.url_addition + this.state.dataset, this.state.selectedItems)
          .then((res) => {
           
            if(res.data.error==null)
            {
                this.CloseDialog();
                this.refresh(1);
                this.setState({ waitting: false });
                this.Snackbar(res.data.messages);
            }
            if(res.data)
            {
                this.setState({ waitting: false });
                this.Snackbar(res.data.messages);
            }

          }).catch((error) => {
            this.setState({ waitting: false });
            alert(error);
            console.log(error)
          });
      }
     
    }


  }

  refresh = (page = 1) => {
    this.setState({ waitting: true, rows: [] });
    
    var param = "";
    param += "?SearchString=" + this.state.search;
    param += "&page=" + page;
    param += "&company_id=" + this.state.company_id;
     


    API.get(this.state.url_addition  + this.state.dataset + param)
      .then((res) => {
         
 
        this.setState(
          {
            
            pageCount: res.data.pagecount,
            rows: res.data.datas,
            page: page,

            waitting: false
          }
        );
        window.scrollTo(0, 0);
      }).catch((error) => {
        this.setState({ waitting: false });
        alert('กรุณาเข้าระบบอีกครั้ง.\n' + error);
        
        console.log(error)
	/*localStorage.removeItem('token');
	localStorage.removeItem('id');
	localStorage.removeItem('user');
	localStorage.removeItem('menus');*/
     //   window.location.replace("/signin");
      });
  }



  componentDidMount() {
    //  alert('componentDidMount');

  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root} >
        <Box m={0}    >
          <Grid container spacing={1}    >

            <Grid item spacing={1} sm={9}  xs={12}  >
              <Breadcrumbs aria-label="breadcrumb">
                <Link color="inherit" to="/home" style={{ color: 'inherit', textDecoration: 'inherit'}} > Home  </Link>
                <Typography color="textPrimary">{this.state.title}</Typography>
              </Breadcrumbs>
            </Grid>
            <Grid item spacing={1} sm={3}   xs={12} justify="flex-end" >

             
                <Grid item>
                  <TextField label="ค้นหา" value={this.state.search}
                    onChange={this.SearchChanged} variant="outlined"  fullWidth dense />
                   
              
              </Grid>

            </Grid>
            <Grid item spacing={1}   >

            <Button
            onClick={this.addnew}
        variant="contained"
        color="primary"
        size="small"
        className={classes.button}
        startIcon={<AddIcon />}
      >
        เพิ่มข้อมูล
      </Button>

             
             
            </Grid>


          </Grid>
        </Box>




        <Box mt={3} width={1}   >
          <Grid  spacing={1}  className={classes.tableWrapper}   >

            <TableContainer component={Paper}>


              <Table className={classes.table}  size="small" aria-label="a dense table" >
                <TableHead>

                  <TableRow>
                    <TableCell width="150px">#</TableCell>
                    <TableCell width="120px" align="left">รหัสพนักงาน</TableCell>
                    <TableCell align="left">รายละเอียด</TableCell>
                     

                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.rows.map((row, index) => (

                    <TableRow key={row.id}>
                      <TableCell component="th" scope="row"  >


                        <IconButton  aria-label="edit" onClick={() => { this.edit(row) }}>
                          <Icon color="primary">create</Icon>
                        </IconButton>
                        <IconButton   aria-label="delete" onClick={() => { this.delete(row) }}>
                          <Icon color="danger">delete_outline</Icon>
                        </IconButton>

                      </TableCell>
                      <TableCell align="left">{row.user_id} </TableCell>
                      <TableCell align="left">{row.email}<br/> {row.first_name}<br/> {row.last_name}</TableCell>

                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box m={1}>

              <Pagination showFirstButton showLastButton variant="outlined" color="secondary" 
              count={this.state.pageCount} page={this.state.page} 
              onChange={this.PageChanged} />
            </Box>

          </Grid>
        </Box>

        <Dialog fullScreen open={this.state.open} onClose={this.CloseDialog} aria-labelledby="form-dialog-title">
          <AppBar className={classes.appBar}>
            <Toolbar variant="dense" theme="dark" >
              <IconButton edge="start" color="inherit" onClick={this.CloseDialog} aria-label="close">
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>{this.state.title}</Typography>
              <IconButton edge="start" color="inherit" onClick={this.save} aria-label="save">
                <SaveIcon />
              </IconButton>

            </Toolbar>
          </AppBar>

          <DialogContent>


            <Grid container spacing={1}  >

              <Grid container   spacing={1}>
     

        

                <Grid item xs={12}  md={3} spacing={1}>
                  <TextField
                    required
                    
                    value={this.state.selectedItems.user_id}
                    onChange={(e) => { if(this.state.mode === 'edit'){this.Snackbar('ไม่อนุญาตให้แก้่ไข-ในโหมดแก้ไขได้.');return false;} const item = this.state.selectedItems; item.user_id = e.target.value; this.setState({ selectedItems: item }); }}
                    maxlength="20"
                    margin="dense"
                    label="รหัสพนักงาน"
                    type="text"
                    fullWidth
                    error={this.state.user_idValidator}
                    helperText={this.state.user_idValidator}
                  />
                </Grid>
                <Grid item xs={12}  md={3} spacing={1}>
                  
                  <TextField
                     InputProps={{
                      readOnly: true,
                    }}
                    disabled
                    value={'@' + this.state.selectedItems.company_id}
                    maxlength="20"
                    margin="dense"
                    label="ในการ signin ทุกครั้งใส่ user@group เสมอ."
                    type="text"
                    fullWidth
                  
                  />
                </Grid>
              
                <Grid item xs={12} md={3}  spacing={1}>
                  <TextField
                    required
                    value={this.state.selectedItems.password}
                    onChange={(e) => { const item = this.state.selectedItems; item.password = e.target.value; this.setState({ selectedItems: item }); }}
                    maxlength="50"
                    margin="dense"
                    label="รหัสผ่าน"
                    type="text"
                    fullWidth
                    error={this.state.passwordValidator}
                    helperText={this.state.passwordValidator}
                  />
                </Grid>

                <Grid item xs={12}  md={3} spacing={1}>
                  <TextField
                    required
                    value={this.state.selectedItems.email}
                    onChange={(e) => { const item = this.state.selectedItems; item.email = e.target.value; this.setState({ selectedItems: item }); }}
                    maxlength="50"
                    margin="dense"
                    autoFocus
                    label="อีเมล์"
                    type="text"
                    fullWidth
                    error={this.state.emailValidator}
                    helperText={this.state.emailValidator}
                  />
                </Grid>

               
              </Grid>

              <Grid container spacing={1} >
           

                <Grid item xs={12}  md={6} spacing={1}>
                  <TextField
                    required
                    value={this.state.selectedItems.first_name}
                    onChange={(e) => { const item = this.state.selectedItems; item.first_name = e.target.value; this.setState({ selectedItems: item }); }}
                    maxlength="50"
                    margin="dense"
                    label="ชื่อ"
                    type="text"
                    fullWidth
                    error={this.state.firstNameValidator}
                    helperText={this.state.firstNameValidator}
                  />
                </Grid>
                <Grid item xs={12}  md={6} spacing={1}>
                  <TextField

                    value={this.state.selectedItems.last_name}
                    onChange={(e) => { const item = this.state.selectedItems; item.last_name = e.target.value; this.setState({ selectedItems: item }); }}
                    maxlength="50"
                    margin="dense"
                    label="สกุล"
                    type="text"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4} spacing={1}>
                  <FormControlLabel control={
                    <Switch color="primary"
                    disabled={this.state.selectedItems.user_id==='admin'?true:false}
                      checked={this.state.selectedItems.active === 'Y' ? true : false}
                      onChange={(e) => { const item = this.state.selectedItems; item.active = e.target.checked ? 'Y' : 'N'; this.setState({ selectedItems: item }); }}
                    />
                  } label="ใช้งาน" />
                </Grid>
              </Grid>
            </Grid>

            <Grid container spacing={1} xs={12}  >
              <TextField
                maxlength="200"
                value={this.state.selectedItems.info}
                onChange={(e) => { const item = this.state.selectedItems; item.info = e.target.value; this.setState({ selectedItems: item }); }}
                margin="dense"
                label="เพิ่มเติม (Enter เพื่อขึ้นบรรทัดใหม่)"
                type="text"
                multiline
                fullWidth
              />
            </Grid>

            <Box m={3}>
              <Grid container spacing={1} xs={12}  >
                <Typography variant="h6" >
                  เมนูการใช้งาน
                </Typography>
              </Grid>
            </Box>

            <Grid  spacing={1} xs={12}  >
              <Box mb={1}>
              <Grid container spacing={1} xs={12}  >
              <FormControlLabel control={
                  <Switch color="primary"
                    checked={this.state.menus.TRN000}
                    onChange={(e) => { const item = this.state.menus; item.TRN000 = e.target.checked; this.setState({ menus: item }); }}
                  />
                } label="บันทึกข้อมูล" />
                <FormControlLabel control={
                  <Switch color="primary"
                    checked={this.state.menus.TRN001}
                    onChange={(e) => { const item = this.state.menus; item.TRN001 = e.target.checked; this.setState({ menus: item }); }}
                  />
                } label="ประเมินความเสี่ยง" />

                <FormControlLabel control={
                  <Switch color="primary"
                    checked={this.state.menus.TRN002}
                    onChange={(e) => { const item = this.state.menus; item.TRN002 = e.target.checked; this.setState({ menus: item }); }}
                  />
                } label="คำนวณผลกำไร"  />
              </Grid>
               

{/*
                <FormControlLabel control={
                  <Switch color="primary"
                    checked={this.state.menus.MST001}
                    onChange={(e) => { const item = this.state.menus; item.MST001 = e.target.checked; this.setState({ menus: item }); }}
                  />
                } label="กลุ่มผู้ใช้งาน" />*/}

<Grid  spacing={1} xs={12}  >
              <FormControlLabel control={
                  <Switch color="primary"
                    checked={this.state.menus.MST002}
                    onChange={(e) => { const item = this.state.menus; item.MST002 = e.target.checked; this.setState({ menus: item }); }}
                  />
                } label="พนักงาน" />
                <FormControlLabel control={
                  <Switch color="primary"
                    checked={this.state.menus.MST003}
                    onChange={(e) => { const item = this.state.menus; item.MST003 = e.target.checked; this.setState({ menus: item }); }}
                  />
                } label="ตารางปฏิทิน" />
             {/*   <FormControlLabel control={
                   <Switch color="primary"
                    checked={this.state.menus.MST004}
                    onChange={(e) => { const item = this.state.menus; item.MST004 = e.target.checked; this.setState({ menus: item }); }}
                  />
                } label="รูปแบบรายงาน" />*/}

                <FormControlLabel control={
                  <Switch color="primary"
                    checked={this.state.menus.SETTINGS}
                    onChange={(e) => { const item = this.state.menus; item.SETTINGS = e.target.checked; this.setState({ menus: item }); }}
                  />
                } label="ตั้งค่าระบบ" />
        </Grid>
              

              </Box>
            </Grid>


          </DialogContent>

        </Dialog>

        <Dialog
          open={this.state.waitting}
          onClose={() => { this.setState({ waitting: false }); }}

        >
          <DialogContent>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <CircularProgress large />
            </Grid>

            <h1>Loading...</h1>
          </DialogContent>

        </Dialog >

        <Snackbar open={this.state.Snackbar} autoHideDuration={4000} onClose={() => { this.setState({ Snackbar: false }); }}>
          <Alert onClose={() => { this.setState({ Snackbar: false }); }} severity="info">{this.state.message}</Alert>
        </Snackbar>

      </div >



    );
  };


}
export default withStyles(styles)(Products)

//export default withStyles(styles, { withTheme: true })(Products);