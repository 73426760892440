import React, { Component } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import {  withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import {Redirect} from 'react-router-dom';
import API from "../utils/API";
import {store} from '../utils/store';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import {
Link
} from "react-router-dom";
//import { AlarmAddRounded } from '@material-ui/icons';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" to="/dashboard" style={{ color: 'inherit', textDecoration: 'inherit'}}>
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}


// const styles = theme => ({
const useStyles = theme => ({
  
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
})

//export default function SignIn() {
 // export default class Signin extends Component {
  class myComponent extends Component {

    constructor(props) {
      super(props)
  
    // alert(new Buffer('your string here').toString('base64'));
     //console.log(Buffer.from("Hello World").toString('base64'));
    // console.log(Buffer.from("SGVsbG8gV29ybGQ=", 'base64').toString('ascii'));

      this.onChangeUserPassword = this.onChangeUserPassword.bind(this);
      this.onChangeUserEmail = this.onChangeUserEmail.bind(this);
      this.onSubmit = this.onSubmit.bind(this);
      this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
      this.onChangeRememberMe = this.onChangeRememberMe.bind(this);
 
      
      

      this.state = {
          url_addition : store.url_addition,
          password: store.password!==null?store.password:'',
          email: store.email!==null?store.email:'',
          remember_me:store.email!==null  ?true:false,
          showPassword: false,
          redirectToReferrer: false,
          Snackbar: false,
          message: "",
      }
  }



  Snackbar = (message) => {
    this.setState({ Snackbar: true, message: message });
  };

  handleClickShowPassword() {

    this.setState({ showPassword: !this.state.showPassword })
}
onChangeUserPassword(e) {
    this.setState({ password: e.target.value })
}

onChangeUserEmail(e) {
    this.setState({ email: e.target.value })
}

onChangeRememberMe(e) {

  this.setState({ remember_me: e.target.checked })
 
}

onSubmit(e) {
    e.preventDefault()
    if (this.state.email === '' || this.state.password === '') {
        this.Snackbar('พบความผิดพลาดข้อมูล ไม่ครบถ้วน!');
        return;
    }
    const userObject = {
      email: this.state.email
        , password: this.state.password
    };

    API.post(this.state.url_addition +  'token', userObject)
        .then((res) => {
            if(res.data.status===200)
            {
              localStorage.setItem('token', res.data.token);

              localStorage.setItem('company_id', res.data.company_id);
              localStorage.setItem('company_id_parent', res.data.company_id_parent);
              localStorage.setItem('admin_flag', res.data.admin_flag);

              window.admin_flag=res.data.admin_flag;
              window.user_id=res.data.user_id;
              window.company_id=res.data.company_id;
              window.company_id_parent=res.data.company_id_parent;

              window.menus=res.data.menus;
              
              if(this.state.remember_me===true)
              {
                localStorage.setItem('email', this.state.email);
                localStorage.setItem('password',  this.state.password);

              }
              else{
                
                localStorage.removeItem('email');
                localStorage.removeItem('password');
              }
              /* localStorage.setItem('id', res.data.id);
               localStorage.setItem('user', this.state.email);
               localStorage.setItem('menus', res.data.menus);*/
             
               this.setState(() => ({
                   redirectToReferrer: true
               }));
               this.props.authorized();
               
             //  console.log(this.props);

             //  this.props.setOpen(false);
             /*  if(window.App)
               {
                window.App.setState({   })
               }*/

            }
            this.Snackbar(res.data.messages);   

          


        }).catch((error) => {
          alert(error);
            console.log(error)
        });

    //  this.setState({ name: '', email: '' })
}

  
    render() {
   
      const { redirectToReferrer } = this.state;

      if (redirectToReferrer === true) {
          return <Redirect to={{ pathname: '/home' }} />
      }

      const { classes, theme } = this.props



      return (
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <form className={classes.form} noValidate>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                value={this.state.email} onChange={this.onChangeUserEmail} 
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type={this.state.showPassword ? 'text' : 'password'}
                id="password"
                autoComplete="current-password"
                value={this.state.password}
                onChange={this.onChangeUserPassword}
              />
              <FormControlLabel
                control={<Checkbox value="remember" color="primary"    checked={this.state.remember_me} onChange={this.onChangeRememberMe} />}
                label="Remember me"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              
                onClick={this.onSubmit}
              >
                Sign In
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link href="#" variant="body2" style={{ color: 'inherit', textDecoration: 'inherit'}}>
                    ลืมรหัสผ่าน?
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="#" variant="body2" style={{ color: 'inherit', textDecoration: 'inherit'}}>
                    {"ยังไม่มีบัญชี? สมัครสมาชิกใหม่"}
                  </Link>
                </Grid>
                

              </Grid>
            </form>
          </div>
          <Box mt={8}>
            <Copyright />
          </Box>

          
        <Snackbar open={this.state.Snackbar} autoHideDuration={4000} onClose={() => { this.setState({ Snackbar: false }); }}>
          <Alert onClose={() => { this.setState({ Snackbar: false }); }} severity="info">{this.state.message}</Alert>
        </Snackbar>

        </Container>
      );
    }
  }

  export default withStyles(useStyles, { withTheme: true })(myComponent)
