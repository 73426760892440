import axios from "axios";
import {store} from './store';
 
const http = axios.create({
  baseURL: "http://lotto.3pl.co.th/",
  responseType: "json",
  headers: {
   // 'Authorization': 'Bearer ' + localStorage.getItem('token'),
   // http://lotto.3pl.co.th/ http://lotto.drop-go.com/
    "Content-type": "application/json" 
  }
});

//axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
 // axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

http.interceptors.request.use (
  function (config) {
    const token = store.token;
    if (token) config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  function (error) {
    return Promise.reject (error);
  }
);

//axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token');

export default http;