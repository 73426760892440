
import React, { Component } from "react";
//, { Component } 
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from "react-router-dom";

import { fade, withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Pagination from '@material-ui/lab/Pagination';

import Box from '@material-ui/core/Box';
import API from "../utils/API";


import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
//import InputBase from '@material-ui/core/InputBase';


//import SearchIcon from '@material-ui/icons/Search';
//import Fab from '@material-ui/core/Fab';

import TextField from '@material-ui/core/TextField';

import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';

import Dialog from '@material-ui/core/Dialog';

import DialogContent from '@material-ui/core/DialogContent';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CloseIcon from '@material-ui/icons/Close';

import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import CircularProgress from '@material-ui/core/CircularProgress';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import Button from '@material-ui/core/Button';

import {store} from '../utils/store';

import Autocomplete from '@material-ui/lab/Autocomplete';

//calendar picker
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import {
  DatePicker,
  //  TimePicker,
  // DateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  paper: {
    margin: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  table: { },
  tableWrapper: {
    width:'100%',
    overflow: 'auto'
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  imageStyle: {
    width: '100px', height: '75px',
  }
});




class Products extends Component {
  //export default function Products() {

  constructor(props) {
    super(props);
    this.PageChanged = this.PageChanged.bind(this);

    if ( window.user_id===undefined || window.user_id===null)
    {
        window.location='/signin';
    }
    

    this.state = {
      url_addition : store.url_addition,
      title: "กลุ่มผู้ใช้งาน",
      dataset: 'companys',
      mode: "add",
      open: false,
      waitting: false,
      rows: [],
      selectedItems: {},
      page: 1,
      pageCount: 1,
      search: "",
      company_id_parents: [],
      selected_company_id_parents:'',
     
      Snackbar: false,
      message: "",
      company_idValidator: "",
      company_nameValidator: "",
      passwordValidator: "",
      urlValidator: "",

    };
    this.fileInput = React.createRef();
    this.forms = React.createRef();

    this.refresh();
  }

  Snackbar = (message) => {
    this.setState({ Snackbar: true, message: message });
  };

  OpenDialog = () => {
    this.setState({ open: true });
  };

  CloseDialog = (value) => {
    this.setState({ open: false });
  };


  SearchChanged = (e) => {
    this.setState({ search: e.target.value });


    if(this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.refresh(1);
    }, 400);



  };

  PageChanged = (event, value) => {
    // this.state.page = value;

    this.refresh(value);
    //this.setState({ page: value });
  };

  lookupTable = (company_id='',parent='') => {
    
     API.get(this.state.url_addition +  'lookup?data=parents&company_id=' + company_id)
       .then((res) => {
         let items = [];
        let selected;
        
        items.push({ company_id:"", company_name: "-" });

         res.data.datas.forEach(element => {
           if(parent!=='')
           {
             if(parent=== element.company_id)
             {
              
              selected={ company_id: element.company_id, company_name: element.company_name };
             }
           }
          items.push({ company_id: element.company_id, company_name: element.company_name });
         });

      
         if(parent!=='')
         {
           
          this.setState({ company_id_parents: items ,selected_company_id_parents:selected});
         }
         else
         {
          this.setState({ company_id_parents: items ,selected_company_id_parents:''});
         }

       }).catch((error) => {
         console.log(error)
       });

  };



  addnew = () => {
    
    this.setState({ mode: "add" });
    this.setState({ waitting: true });


      let items = {
        company_id_parent: "",
        company_id: "",
        company_name: "",
        expired_date: "",
        password: Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 6),
        api_url: "-",
        active: 'Y'
      };

      this.setState({ selectedItems: items });
      this.lookupTable();
      this.OpenDialog();
      this.setState({ waitting: false });


  }




  edit = (items) => {

    //this.state.selectedItems.active
    
    this.ClearValidation();
    this.setState({ mode: "edit" });
    this.lookupTable(items.company_id,items.company_id_parent);
    this.setState({ selectedItems: items  });
    this.OpenDialog();

  }

  delete = (items) => {
    if (window.confirm('ท่านต้องการลบข้อมูล? ข้อมูล=' + items.company_id)) {
      API.delete(this.state.url_addition  + this.state.dataset + '/' + items.company_id)
        .then((res) => {

          this.Snackbar('ลบข้อมูลแล้ว!');
          this.refresh(this.state.page);

        }).catch((error) => {

          alert(error);
          console.log(error)
        });
    }

  }

  ClearValidation = () => {
    this.setState({ company_nameValidator: "", passwordValidator: "", urlValidator: "" });
  }

  validation = () => {
    let err = '';
//this.state.selected_company_id_parents.company_id

if (this.state.selectedItems.company_id === this.state.selected_company_id_parents.company_id) {

  if (err !== '') { err += "," };
  err += "ไม่สามารถเลือกผู้ส่งเป็นตัวเองได้.";
}

if (this.state.selectedItems.company_id === '') {

  this.setState({ company_idValidator: "ต้องการระบุ และขนาดมากสุด 20 ตัว." });
  if (err !== '') { err += "," };
  err += "รหัส";
}
else {
  this.setState({ company_idValidator: "" });
}

    if (this.state.selectedItems.company_name === '') {

      this.setState({ company_nameValidator: "ต้องการระบุ และขนาดมากสุด 50 ตัว." });
      if (err !== '') { err += "," };
      err += "รหัสบริษัท";
    }
    else {
      this.setState({ company_nameValidator: "" });
    }

    if (this.state.selectedItems.password === '') {

      this.setState({ passwordValidator: "ต้องการระบุ และขนาดมากสุด 20 ตัว." });
      if (err !== '') { err += "," };
      err += "รหัสผ่าน";
    }
    else {
      this.setState({ passwordValidator: "" });
    }

   
    //urlValidator: "",
    if (this.state.selectedItems.api_url === '') {

      this.setState({ urlValidator: "ต้องการระบุ และขนาดมากสุด 100 ตัว." });
      if (err !== '') { err += "," };
      err += "ชื่อ";
    }
    else {
      this.setState({ urlValidator: "" });
    }
    return err;
  }

  
  formatDate = (date) => {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }

  save = () => {
   

    let err = this.validation();
     
    const datas = this.state.selectedItems;
    datas.expired_date= this.formatDate(datas.expired_date);

    
    if (err !== '') {
      this.Snackbar('พบข้อผิดพลาด! ' + err);
    }
    else {

      this.setState({ waitting: true });
      const datas = this.state.selectedItems;
     

       datas.company_id_parent='';

      if(this.state.selected_company_id_parents!==null && this.state.selected_company_id_parents!=='')
      {
        datas.company_id_parent=this.state.selected_company_id_parents.company_id;
      }
    
      this.setState({ selectedItems: datas });

      if (this.state.mode === 'edit') {
        API.put(this.state.url_addition + this.state.dataset  + '/' + this.state.selectedItems.company_id , this.state.selectedItems )
          .then((res) => {
            console.log(res.data);
            this.CloseDialog();
            this.setState({ waitting: false });
            if(res.data.error==null)
            {
                this.Snackbar(res.data.messages);
            }
           
           
          }).catch((error) => {
            this.setState({ waitting: false });
            alert(error);
            console.log(error)
          });
      }
      if (this.state.mode === 'add') {
        API.post(this.state.url_addition + this.state.dataset, this.state.selectedItems)
          .then((res) => {
            console.log(res.data);
          
            
            if(res.data.error==null)
            {
                this.CloseDialog();
                this.refresh(1);
                this.setState({ waitting: false });
                this.Snackbar(res.data.messages);
            }
            if(res.data)
            {
                this.setState({ waitting: false });
                this.Snackbar(res.data.messages);
            }
           

           
          }).catch((error) => {
            this.setState({ waitting: false });
            alert(error);
            console.log(error)
          });
      }
      
    }


  }

  refresh = (page = 1) => {
    this.setState({ waitting: true, rows: [] });
    
    var param = "";
    param += "?SearchString=" + this.state.search;
    param += "&page=" + page;

    API.get(this.state.url_addition  + this.state.dataset + param)
      .then((res) => {
         
 
        this.setState(
          {
            
            pageCount: res.data.pagecount,
            rows: res.data.datas,
            page: page,

            waitting: false
          }
        );
        window.scrollTo(0, 0);
      }).catch((error) => {
        this.setState({ waitting: false });
        alert(error);
        console.log(error)
	localStorage.removeItem('token');
	localStorage.removeItem('id');
	localStorage.removeItem('user');
	localStorage.removeItem('menus');
     //   window.location.replace("/signin");
      });
  }



  componentDidMount() {
    //  alert('componentDidMount');

  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root} >
        <Box m={0}    >
          <Grid container spacing={1}    >

            <Grid item spacing={1} sm={9} xs={12}  >
              <Breadcrumbs aria-label="breadcrumb">
                <Link color="inherit" to="/home" style={{ color: 'inherit', textDecoration: 'inherit'}} > Home  </Link>
                <Typography color="textPrimary">{this.state.title}</Typography>
              </Breadcrumbs>
            </Grid>
            <Grid item spacing={1} sm={3}   xs={12} justify="flex-end" >

             
             
                <Grid item>
                  <TextField label="ค้นหา" value={this.state.search}
                    onChange={this.SearchChanged} variant="outlined"  fullWidth dense />
                   
                </Grid>
              
               

            </Grid>
            <Grid item spacing={1}   >

            <Button
            onClick={this.addnew}
        variant="contained"
        color="primary"
        size="small"
        className={classes.button}
        startIcon={<AddIcon />}
      >
        เพิ่มข้อมูล
      </Button>

             
             
            </Grid>


          </Grid>
        </Box>




        <Box mt={3} width={1}   >
          <Grid  spacing={1}  className={classes.tableWrapper}   >

            <TableContainer component={Paper}>


              <Table className={classes.table}  size="small" aria-label="a dense table" >
                <TableHead>

                  <TableRow>
                    <TableCell width="150px">#</TableCell>
                    <TableCell width="120px" align="left">รหัสพนักงาน</TableCell>
                    <TableCell width="120px" align="left">รหัสผู้ส่ง</TableCell>
                    <TableCell width="120px">รหัสผ่าน</TableCell>
                    <TableCell align="left">ชื่อ</TableCell>
                    <TableCell align="left">ใช้งาน</TableCell>

                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.rows.map((row, index) => (

                    <TableRow key={row.id}>
                      <TableCell component="th" scope="row"  >


                        <IconButton aria-label="edit" onClick={() => { this.edit(row) }}>
                          <Icon color="primary">create</Icon>
                        </IconButton>
                        <IconButton aria-label="delete" onClick={() => { this.delete(row) }}>
                          <Icon color="danger">delete_outline</Icon>
                        </IconButton>

                      </TableCell>
                      <TableCell align="left">{row.company_id} </TableCell>
                      <TableCell align="left">{row.company_id_parent} </TableCell>
                      <TableCell align="left">{row.password} </TableCell>
                      <TableCell align="left">{row.company_name} </TableCell>
                      <TableCell align="left">{row.active} </TableCell>

                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box m={1}>

              <Pagination showFirstButton showLastButton variant="outlined" color="secondary" 
              count={this.state.pageCount} page={this.state.page} 
              onChange={this.PageChanged} />
            </Box>

          </Grid>
        </Box>

        <Dialog fullScreen open={this.state.open} onClose={this.CloseDialog} aria-labelledby="form-dialog-title">
          <AppBar className={classes.appBar}>
            <Toolbar variant="dense" theme="dark" >
              <IconButton edge="start" color="inherit" onClick={this.CloseDialog} aria-label="close">
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>{this.state.title}</Typography>
              <IconButton edge="start" color="inherit" onClick={this.save} aria-label="save">
                <SaveIcon />
              </IconButton>

            </Toolbar>
          </AppBar>

          <DialogContent>


            <Grid container spacing={1}  >

              <Grid container   spacing={1}>
                <Grid item xs={12} spacing={1}>
                  <TextField
                    required
                    autoFocus
                    value={this.state.selectedItems.company_id}
                    onChange={(e) => { const item = this.state.selectedItems; item.company_id = e.target.value; this.setState({ selectedItems: item }); }}
                    maxlength="20"
                    margin="dense"
                    label="รหัสกลุ่มผู้ใช้งาน"
                    type="text"
                    fullWidth
                    error={this.state.company_idValidator}
                    helperText={this.state.company_idValidator}
                  />
                </Grid>
                <Grid item xs={12} spacing={1}>
                  <TextField
                    required
                    value={this.state.selectedItems.company_name}
                    onChange={(e) => { const item = this.state.selectedItems; item.company_name = e.target.value; this.setState({ selectedItems: item }); }}
                    maxlength="50"
                    margin="dense"
                    
                    label="ชื่อ กลุ่ม/บริษัท/บุคคล"
                    type="text"
                    fullWidth
                    error={this.state.company_nameValidator}
                    helperText={this.state.company_nameValidator}
                  />
                </Grid>

                <Grid item xs={6} spacing={1}>
                  <TextField
                     
                    
                    value="admin"
                    disabled
                    maxlength="20"
                    margin="dense"
                    label="รหัสพนักงาน"
                    type="text"
                    fullWidth
                    
                  />
                </Grid>
              
                <Grid item xs={6} spacing={1}>
                  <TextField
                    required
                    value={this.state.selectedItems.password}
                    onChange={(e) => { const item = this.state.selectedItems; item.password = e.target.value; this.setState({ selectedItems: item }); }}
                    maxlength="50"
                    margin="dense"
                    label="รหัสผ่าน"
                    type="text"
                    fullWidth
                    error={this.state.passwordValidator}
                    helperText={this.state.passwordValidator}
                  />
                </Grid>

                <Grid item xs={12} spacing={1}>
                  <TextField
                    required
                    value={this.state.selectedItems.api_url}
                    onChange={(e) => { const item = this.state.selectedItems; item.api_url = e.target.value; this.setState({ selectedItems: item }); }}
                    maxlength="100"
                    margin="dense"
                    label="URL/API"
                    type="text"
                    fullWidth
                    error={this.state.urlValidator}
                    helperText={this.state.urlValidator}
                  />
                </Grid>
                
              </Grid>

              <Grid  item xs={12}  spacing={1}>
              <Autocomplete
                id="cbo_company_id_parent"
                options={this.state.company_id_parents}
                getOptionLabel={(option) => option.company_name}
                renderInput={(params) => <TextField {...params} label="ส่งให้กับ" variant="outlined" />}
                onChange={(event, value) => { this.setState({selected_company_id_parents:value}); }    }
                value={this.state.selected_company_id_parents}
              />
            </Grid>

            <Grid item xs={12} spacing={1}>

<MuiPickersUtilsProvider utils={DateFnsUtils}>
<DatePicker
inputVariant="outlined"
format="dd/MM/yyyy"
value={this.state.selectedItems.expired_date}
onChange={(val) => { const item = this.state.selectedItems; item.expired_date = val;  this.setState({ selectedItems: item }); }}
label="วันทึ่สิ้นสุดการใช้งาน"
fullWidth

/>
</MuiPickersUtilsProvider>

              <Grid container spacing={1} >
               
                
                <Grid item xs={12} spacing={1}>
                  <FormControlLabel control={
                    <Switch color="primary"
                      checked={this.state.selectedItems.active === 'Y' ? true : false}
                      onChange={(e) => { const item = this.state.selectedItems; item.active = e.target.checked ? 'Y' : 'N'; this.setState({ selectedItems: item }); }}
                    />
                  } label="ใช้งาน" />
                </Grid>
              </Grid>
            </Grid>

           
    


              </Grid>
         

          </DialogContent>

        </Dialog>

        <Dialog
          open={this.state.waitting}
          onClose={() => { this.setState({ waitting: false }); }}

        >
          <DialogContent>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <CircularProgress large />
            </Grid>

            <h1>Loading...</h1>
          </DialogContent>

        </Dialog >

        <Snackbar open={this.state.Snackbar} autoHideDuration={4000} onClose={() => { this.setState({ Snackbar: false }); }}>
          <Alert onClose={() => { this.setState({ Snackbar: false }); }} severity="info">{this.state.message}</Alert>
        </Snackbar>

      </div >



    );
  };


}
export default withStyles(styles)(Products)

//export default withStyles(styles, { withTheme: true })(Products);