import React, { Component } from 'react';


//import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';


import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import { withStyles } from '@material-ui/core/styles';
//import Container from '@material-ui/core/Container';

import { Redirect } from 'react-router-dom';
import API from "../utils/API";
import { store } from '../utils/store';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import Fab from '@material-ui/core/Fab';

import Tooltip from '@material-ui/core/Tooltip';

import {
  Link
} from "react-router-dom";
//import { AlarmAddRounded } from '@material-ui/icons';


import Autocomplete from '@material-ui/lab/Autocomplete';


//calendar picker
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import {
  DatePicker,
  //  TimePicker,
  // DateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

//Dialog
import Dialog from '@material-ui/core/Dialog';
//import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
//import DialogContentText from '@material-ui/core/DialogContentText';
//import DialogTitle from '@material-ui/core/DialogTitle';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';

import TicketDetail from "./ticketdetail";
import TextInput from "./textInput";
//import { FastfoodOutlined } from '@material-ui/icons';

import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}




// const styles = theme => ({
const useStyles = theme => ({


  paper: {
    padding: theme.spacing(1),
    // textAlign: 'center',
  },
  item: {
    marginTop: theme.spacing(1),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  hide: { display: 'none' },

  fab_save: {
    margin: '0px',
    top: 'auto',
    right: '160px',
    bottom: '20px',
    left: 'auto',
    position: 'fixed',
  },
  fab_text: {
    margin: '0px',
    top: 'auto',
    right: '90px',
    bottom: '20px',
    left: 'auto',
    position: 'fixed',
  },
  fab_report: {
    margin: '0px',
    top: 'auto',
    right: '20px',
    bottom: '20px',
    left: 'auto',
    position: 'fixed',
  },



  fab_datas_list: {
    /*  margin: '0px',
      top: '80px',
      right: '20px',
      bottom: 'auto',
      left: 'auto',
      position: 'fixed',*/
  },

})




class transactons extends Component {

  constructor(props) {
    super(props)


    if (window.user_id === undefined || window.user_id === null) {
      window.location = '/signin';

    }

    let user_id = '';
    let company_id = '';
    let company_id_parent='';

    if (window.user_id) {
      user_id = window.user_id;
    }

    if (window.company_id) {
      company_id = window.company_id;
    }

    if (window.company_id_parent) {
      company_id_parent = window.company_id_parent;
    }

    let tickets = [];
    for (var i = 1; i <= 99; i++) {
      tickets.push({ text: i < 10 ? '0' + i.toString() : i.toString() });
    }

    let minrows=[]
    minrows.push({ text:'10' });
    minrows.push({ text:'20' });
    minrows.push({ text:'50' });
    minrows.push({ text:'100' });
    minrows.push({ text:'200' });
    minrows.push({ text:'300' });
    minrows.push({ text:'400' });
    minrows.push({ text:'500' });
    minrows.push({ text:'1000' });

    let minbuys=[]
    minbuys.push({ text:'500' });
    minbuys.push({ text:'1000' });
    minbuys.push({ text:'1500' });
    minbuys.push({ text:'2000' });


    //this.accordionContent =[];
   

    let selected_minrow=localStorage.getItem('selected_minrow');
    let selected_minbuy=localStorage.getItem('selected_minbuy');
    let use_group= localStorage.getItem('use_group')===null?false:localStorage.getItem('use_group');
    use_group=use_group==='true'?true:false;
    let use_three_bottom=localStorage.getItem('use_three_bottom')===null?false:localStorage.getItem('use_three_bottom');
    use_three_bottom=use_three_bottom==='true'?true:false;

    this.state = {
      locked: false,
      use_three_bottom:use_three_bottom,
      use_group:  use_group,
      title: 'บันทึกข้อมูล',
      isChange: false,
      selected_date: localStorage.getItem('date') === null ? new Date() : localStorage.getItem('date'),
      url_addition: store.url_addition,
      company_id: company_id,
      company_id_parent: company_id_parent,
      user_id: user_id,

      SelectedItem: 0,
      redirectToReferrer: false,
      Snackbar: false,
      message: "",
      rows: [
        //  { number: '50', top: '10',bottom: '11' },
        { number: '', top: '', bottom: '', option: ''  },
      ],
      minbuys:minbuys,
      search: '',
      shows: [],
      // this.setState({shows: this.state.rows.filter(item => item !== e.target.value);});
      senders: [],
     
      tickets: tickets,
      minrows: minrows===null?20:minrows ,
      lists_tickets:[] ,
     
      selected_sender: null,
      selected_group: {text:'01'},
      selected_ticket: {text:'01'},
      selected_minrow: {text: selected_minrow===null?'20':selected_minrow},
      selected_minbuy: {text: selected_minbuy===null?'1000':selected_minbuy},

      editable: false,
      accordionContent: [],
      open_reports: false,
      open_texts: false,
      open_datas_list: false,
    }

    this.colorNormalTwo = 'rgba(100, 149, 237,0.5)';
    this.colorNormalThree = 'rgba(92, 184, 92,0.5)';
    this.colorNormalOne = 'rgba(255, 191, 0,0.5)';

    this.colorBlank = 'rgba(217, 83, 79,0.5)';
    this.maxdate();
    this.lookupTable();
  }



  Snackbar = (message) => {
    this.setState({ Snackbar: true, message: message });
  };


  selected_tiket = (row) => {

    /* this.setState({ 
       selected_sender: {sender_id:row.sender_id,sender_name:row.sender_name},
       selected_group: {text:row.group_id},
       selected_ticket:{text:row.ticket_id},
       open_datas_list:false 
     });*/

    this.state.selected_sender = { sender_id: row.sender_id, sender_name: row.sender_name };
    this.state.selected_group = { text: row.group_id };
    this.state.selected_ticket = { text: row.ticket_id };
    this.state.open_datas_list = false;
    this.refresh();

  }

  getTicket = () => {
 
    try{
      if(this.state.selected_sender.sender_id===null)
      {
        this.Snackbar('กรุณาเลือกผู้ส่งข้อมูล.');
        return false;
      }
    }
    catch{ this.Snackbar('กรุณาเลือกผู้ส่งข้อมูล.');return false;}
   
    

    var company_id='';
    var sender_id='';
    company_id= this.state.company_id;
    sender_id=this.state.selected_sender.sender_id;

   /* if(this.state.company_id_parent!=='')
    {
      company_id= this.state.company_id;
      sender_id=this.state.company_id_parent;
    }
    else
    {
      sender_id= this.state.company_id;
      company_id=this.state.selected_sender.sender_id;
    }*/

    API.get(this.state.url_addition + 'datas/tickets?company_id=' + company_id + '&sender_id=' + sender_id  + '&company_id_parent=' + this.state.company_id_parent + "&date=" + this.formatDate(this.state.selected_date.toString()))
      .then((res) => {
        let items = [];


        res.data.datas.forEach(element => {
          items.push({
            data_date: element.data_date,
            sender_id: element.sender_id,
            sender_name: element.sender_name,
            group_id: element.group_id,
            ticket_id: element.ticket_id
          });
        });
        this.setState({lists_tickets:items, open_datas_list: true });
        //  tickets: items 


      }).catch((error) => {
        console.log(error)
        alert(error);
      });


  }

  maxdate = () => {
    API.get(this.state.url_addition + 'api/maxdate?company_id=' + this.state.company_id )
    .then((res) => {
       
      if (res.data.error) {
        this.Snackbar(res.data.error);
        this.state.locked=true;
        window.locked=true;
      }
      else
      {
         this.setState({ selected_date:res.data.datas});
      }

    }).catch((error) => {
      this.Snackbar(error)
     
    });

}

  lookupTable = () => {
    API.get(this.state.url_addition + 'lookup?data=senders&company_id=' + this.state.company_id + '&company_id_parent=' + this.state.company_id_parent)
      .then((res) => {
        let items = [];
        let last;

        res.data.datas.forEach(element => {
          if(element.sender_id===window.user_id)
          {
            last={ sender_name: element.sender_name, sender_id: element.sender_id };
          }
         
          items.push({ sender_name: element.sender_name, sender_id: element.sender_id });
        });

        if(window.admin_flag==='N')
        {
          this.setState({ senders: items,selected_sender:last });
          this.refresh();
        }
        else
        {
          this.setState({ senders: items });
        }
       
        this.setState({ senders: items });


      }).catch((error) => {
        console.log(error)
      });

  };

  formatDate = (date) => {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }

  createMinrow = () => {
     
      if(parseInt(this.state.rows.length) < parseInt(this.state.selected_minrow.text) )
      {
        
        for (var i = this.state.rows.length; i <= parseInt(this.state.selected_minrow.text); i++) {
          
          this.state.rows.push({ number: '', top: '', bottom: '',option:'' });
          
        }
      }
  }

  refresh = () => {

    if (this.state.selected_date !== null && this.state.selected_date !== ''
      && this.state.selected_sender !== null && this.state.selected_sender !== ''
      && this.state.selected_group !== null && this.state.selected_group !== ''
      && this.state.selected_ticket !== null && this.state.selected_ticket !== '') {

      var param = 'datas?company_id=' + this.state.company_id;

      param += "&company_id_parent=" + this.state.company_id_parent.toString();

      param += "&date=" + this.formatDate(this.state.selected_date.toString());
      param += "&sender=" + this.state.selected_sender.sender_id.toString();
      param += "&group=" + this.state.selected_group.text.toString();
      param += "&ticket=" + this.state.selected_ticket.text.toString();
      param += "&user=" + this.state.user_id.toString();

      this.state.accordionContent = [];
      this.setState({});
      
      window.locked=false;

      API.get(this.state.url_addition + param, this.state.rows)
        .then((res) => {
           
          if (res.data.error) {
            this.Snackbar(res.data.error);
            this.state.locked=true;
            window.locked=true;
          }


          this.state.isChange = false;
          this.state.rows = res.data.datas;
         

         // this.state.rows.push({ number: '', top: '', bottom: '',option:'' });
         this.createMinrow();
         
          this.state.shows = this.state.rows;

          this.setState({ locked:window.locked, editable: true });
         

        }).catch((error) => {
          console.log(error)
          alert('กรุณาทำการเข้าระบบใหม่อีกครั้ง. error=' + error);
        });

    }
    else {
      this.setState({ editable: false });
    }


  }

  setRowsBlank = () => {
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {

      try {
        for (var i = 0; i < this.state.shows.length; i++) {
          //console.log(this.state.shows);
          if (this.state.shows[i].number !== '') {
            if (this.state.shows[i].number.length === 2) {
              this.state.accordionContent[i].style.background = this.colorNormalTwo;
            }
            else {
              if (this.state.shows[i].number.length === 3) {
                this.state.accordionContent[i].style.background = this.colorNormalThree;
              }
              else {
                if (this.state.shows[i].number.length === 1) {
                  this.state.accordionContent[i].style.background = this.colorNormalOne;
                }
              }
            }
          }
          else {

            this.state.accordionContent[i].style.background = this.colorBlank;
          }

        }
      }
      catch (error) { console.log(error); }


    }, 200);

  }

  SearchChanged = (e) => {
    this.setState({ search: e.target.value });


    if (this.searchtimeout) clearTimeout(this.searchtimeout);
    this.searchtimeout = setTimeout(() => {

      this.setState({ shows: this.state.rows.filter(item => item.number.includes(e.target.value)) });

    }, 200);



  };

  // 

  saveAll = () => {
    //if(this.state.company_id_parent===''){return false;}

    if (this.state.selected_sender !== null && this.state.selected_group !== null && this.state.selected_ticket !== null) {

      var param = 'datas?company_id=' + this.state.company_id;

      param += "&use_three_bottom=" + this.state.use_three_bottom;
      param += "&date=" + this.formatDate(this.state.selected_date.toString());
      param += "&sender=" + this.state.selected_sender.sender_id.toString();
      param += "&group=" + this.state.selected_group.text.toString();
      param += "&ticket=" + this.state.selected_ticket.text.toString();
      param += "&user=" + this.state.user_id.toString();
     

      
      window.locked=false;
      API.post(this.state.url_addition + param, this.state.rows)
        .then((res) => {

          if (res.data.error) {
            this.Snackbar(res.data.error);
            window.locked=true;
            this.setState({locked:true});
          }
          else {
            if (res.data.messages) {
              this.Snackbar(res.data.messages);
            }

            this.state.isChange = false;

          }

        }).catch((error) => {
          console.log(error)
          alert('กรุณาทำการเข้าระบบใหม่อีกครั้ง. error=' + error);
        });


    }
    else {
      this.Snackbar("กรุณาระบุ ผุู้ส่ง/กลุ่ม/ชุด");
    }

  }

  /*addNew=index=>{

  }*/

  inserted = (event, index) => {
    //arr.insert(index, item)

    // console.log(this.accordionContent[index]);

    if (index === this.state.shows.length - 1) {
      this.state.rows.push({ number: '', top: '', bottom: '' , option: '' });

      this.state.shows = this.state.rows;
      this.state.isChange = true;
     // this.Snackbar('เพิ่มรายการใหม่!');
     this.setState({});

      if (this.focustimeout) clearTimeout(this.focustimeout);
      this.focustimeout = setTimeout(() => {

        if (this.state.accordionContent[index + 1]) {
          this.state.accordionContent[index + 1].focus();
        }
      }, 200);

    }
    else {
      var temp = [];
      for (var i = 0; i <= this.state.rows.length - 1; i++) {

        //  console.log(this.state.rows[i]);
        temp.push(this.state.rows[i]);
        if (index === i) {
          temp.push({ number: '', top: '', bottom: '', option: '' });
        }

      }

      // console.log(temp);

      this.state.rows = temp;
      this.state.shows = temp;

      //this.Snackbar('บันทึกข้อมูล!');
      this.setState({});
      if (this.state.accordionContent[index + 1]) {
        this.state.accordionContent[index + 1].focus();
      }



    }

  }


  up = index => {

    if (index !== 0) {
      var temp = this.state.rows[index - 1];
      this.state.rows[index - 1] = this.state.rows[index];
      this.state.rows[index] = temp;

      this.state.isChange = true;
      //this.Snackbar('ย้่ายขึ้น!');
      this.setState({});
    }

  }

  down = index => {

    if (index < this.state.shows.length - 2) //exclude lastline and array max
    {
      var temp = this.state.rows[index + 1];
      this.state.rows[index + 1] = this.state.rows[index];
      this.state.rows[index] = temp;

      this.state.isChange = true;
      //this.Snackbar('ย้่ายลง!');
      this.setState({});
    }
  }

  deleted = index => {
    this.state.isChange = true;
    this.state.rows.splice(index, 1);
    this.state.shows = this.state.rows;
    this.Snackbar('ลบข้อมูลแล้ว!');



  }

  confirmSave = () => {
    if(this.state.locked===true){return true;}

    if (this.state.shows.length > 0 && this.state.isChange === true) {
      if (window.confirm('มีการเปลี่ยนแปลงข้อมูลท่านบันทึกหรือไม่?')) {
        this.saveAll();
        return false;
      }
    }

    return true;
  }

  handleNumber = event => {
    //this.Snackbar(event.keyCode);
    //96-105 is padnumber: 48-57: 37/39 left right arrow
    if (event.keyCode > 31 && event.keyCode !== 39 && event.keyCode !== 37 && (event.keyCode < 48 || event.keyCode > 57) && (event.keyCode < 96 || event.keyCode > 105)) {
      event.preventDefault();
      return false;
    }

    if (event.keyCode === 13) {


      if (!(event.target.value.length > 3)) {
        const form = event.target.form;

        const index = Array.prototype.indexOf.call(form, event.target);

        if (form.elements[index + 2]) {
          form.elements[index + 2].focus();
        }

        if(this.state.shows[this.state.SelectedItem].number.length !== 3)
        {
          this.state.shows[this.state.SelectedItem].option='';
          this.setState({});
        }

      }
      else {
        this.Snackbar('โปรดระบุตัวเลข ไม่มากกว่า 3 ตัว.');
      }

      event.preventDefault();
    }
    else {
      /* if( event.target.value.length>=3) //limit length3
       {
         event.preventDefault();
       }*/
    }

  }


  AutoRotageNumber() 
  {
   
    if(this.state.shows[this.state.SelectedItem].number.length === 1)
    {
      this.Snackbar('ไม่สามารถสร้างในรูปแบบเลขวิ่งได้.');
      return false;
    }

    let _err='';

    if(this.state.shows[this.state.SelectedItem].number === '')
    {
      _err+=" ,ตัวเลขซื้อว่าง.";
    }

    if(this.state.shows[this.state.SelectedItem].number.length === 2)
    {
      if(this.state.shows[this.state.SelectedItem].top === '' && this.state.shows[this.state.SelectedItem].bottom === '')
      {
      _err+=" ,ระบุตัวเลข บน หรือ ล่าง อย่างน้อย.";
      }
    }

    if(this.state.shows[this.state.SelectedItem].number.length === 2)
    {
      if(this.state.shows[this.state.SelectedItem].top === '' && this.state.shows[this.state.SelectedItem].bottom === '' && this.state.shows[this.state.SelectedItem].option === '')
      {
      _err+=" ,ระบุตัวเลข บน ล่าง, หรือ โต๊ด อย่างน้อย.";
      }
    }

 
    if(_err!=='')
    {
      this.Snackbar(_err);
      return false;
    }

    if(window.confirm('ท่านต้องการ สร้างการกลับเลขแบบอัตโนมัติ หรือไม่? เลข=' + this.state.shows[this.state.SelectedItem].number  ))
    {
      // two digit
      if(this.state.shows[this.state.SelectedItem].number.length === 2)
      {
       
        this.AutoTwo();
      }
      if(this.state.shows[this.state.SelectedItem].number.length === 3)
      {
       
        this.AutoThree();
      }
      

    }

  }

  AutoTwo = event => {
    let index=this.state.SelectedItem;
    let d1=this.state.shows[this.state.SelectedItem].number.toString().substring(0,1);
    let d2=this.state.shows[this.state.SelectedItem].number.toString().substring(1,2);
    
    if(d1.toString()===d2.toString())
    {
      this.Snackbar('ไม่สามารถสร้า่งตัวเลขเดียวกันได้.');
      return false;
    }


    var temp = [];
    for (var i = 0; i <= this.state.rows.length - 1; i++) {

      if (i === this.state.SelectedItem) {
        //self
        temp.push({ number: d1.concat(d2), 
          top: this.state.shows[this.state.SelectedItem].top, 
          bottom: this.state.shows[this.state.SelectedItem].bottom,
          option: this.state.shows[this.state.SelectedItem].option 
          });
          //+ swap
        temp.push({ number: d2.concat(d1), 
        top: this.state.shows[this.state.SelectedItem].top, 
        bottom: this.state.shows[this.state.SelectedItem].bottom,
        option: this.state.shows[this.state.SelectedItem].option 
        });
      }
      else
      {
        temp.push(this.state.rows[i]);
      }

    }

    // console.log(temp);

    this.state.rows = temp;
    this.state.shows = temp;

    this.Snackbar('สร้างกลับเลขเสร็จสิ้น!');
    this.setState({});
    if (this.state.accordionContent[index + 1]) {
      this.state.accordionContent[index + 1].focus();
    }

  }

  AutoThree= event => {
    let index=this.state.SelectedItem;
    let d1=this.state.shows[this.state.SelectedItem].number.toString().substring(0,1);
    let d2=this.state.shows[this.state.SelectedItem].number.toString().substring(1,2);
    let d3=this.state.shows[this.state.SelectedItem].number.toString().substring(2,3);

    if(d1.toString()===d2.toString() && d1.toString()===d3.toString() && d2.toString()===d3.toString())
    {
      this.Snackbar('ไม่สามารถสร้า่งตัวเลขเดียวกันได้.');
      return false;
    }


    var temp = [];
    for (var i = 0; i <= this.state.rows.length - 1; i++) {

      if (i === this.state.SelectedItem) {
       
        if(d1.toString()!==d2.toString() && d1.toString()!==d3.toString() && d2.toString()!==d3.toString())
        {
           //6swap
            //self
            temp.push({ number: d1.concat(d2,d3), 
            top: this.state.shows[this.state.SelectedItem].top, 
            bottom: this.state.shows[this.state.SelectedItem].bottom,
            option: this.state.shows[this.state.SelectedItem].option 
            });

            temp.push({ number: d1.concat(d3,d2), 
            top: this.state.shows[this.state.SelectedItem].top, 
            bottom: this.state.shows[this.state.SelectedItem].bottom,
            option: this.state.shows[this.state.SelectedItem].option 
            });

            temp.push({ number: d2.concat(d1,d3), 
            top: this.state.shows[this.state.SelectedItem].top, 
            bottom: this.state.shows[this.state.SelectedItem].bottom,
            option: this.state.shows[this.state.SelectedItem].option 
            });

            temp.push({ number: d2.concat(d3,d1), 
            top: this.state.shows[this.state.SelectedItem].top, 
            bottom: this.state.shows[this.state.SelectedItem].bottom,
            option: this.state.shows[this.state.SelectedItem].option 
            });

            temp.push({ number: d3.concat(d1,d2), 
              top: this.state.shows[this.state.SelectedItem].top, 
              bottom: this.state.shows[this.state.SelectedItem].bottom,
              option: this.state.shows[this.state.SelectedItem].option 
              });

            temp.push({ number: d3.concat(d2,d1), 
            top: this.state.shows[this.state.SelectedItem].top, 
            bottom: this.state.shows[this.state.SelectedItem].bottom,
            option: this.state.shows[this.state.SelectedItem].option 
            });

        }
        else{
        
          let dd1='';
          let dd2='';
          if(d1.toString()!==d3.toString() && d1.toString()===d2.toString())
          {
            //112
            //121
            //211
            dd1=d1;dd2=d3;
            temp.push({ number: dd1.concat(dd1,dd2), 
            top: this.state.shows[this.state.SelectedItem].top, 
            bottom: this.state.shows[this.state.SelectedItem].bottom,
            option: this.state.shows[this.state.SelectedItem].option 
            });

            //swap only 3
            temp.push({ number: dd1.concat(dd2,dd1), 
            top: this.state.shows[this.state.SelectedItem].top, 
            bottom: this.state.shows[this.state.SelectedItem].bottom,
            option: this.state.shows[this.state.SelectedItem].option 
            });

            temp.push({ number: dd2.concat(dd1,dd1), 
            top: this.state.shows[this.state.SelectedItem].top, 
            bottom: this.state.shows[this.state.SelectedItem].bottom,
            option: this.state.shows[this.state.SelectedItem].option 
            });
          }
          if(d1.toString()!==d2.toString() && d1.toString()===d3.toString())
          {
             //121
            //112
            //211
            dd1=d1;dd2=d2;
            temp.push({ number: dd1.concat(dd2,dd1), 
              top: this.state.shows[this.state.SelectedItem].top, 
              bottom: this.state.shows[this.state.SelectedItem].bottom,
              option: this.state.shows[this.state.SelectedItem].option 
              });
  
              //swap only 3
              temp.push({ number: dd1.concat(dd1,dd2), 
              top: this.state.shows[this.state.SelectedItem].top, 
              bottom: this.state.shows[this.state.SelectedItem].bottom,
              option: this.state.shows[this.state.SelectedItem].option 
              });
  
              temp.push({ number: dd2.concat(dd1,dd1), 
              top: this.state.shows[this.state.SelectedItem].top, 
              bottom: this.state.shows[this.state.SelectedItem].bottom,
              option: this.state.shows[this.state.SelectedItem].option 
              });
          }
          if(d1.toString()!==d2.toString() && d2.toString()===d3.toString())
          {
             //211
            //121
            //112
            dd1=d2;dd2=d1;
            temp.push({ number: dd2.concat(dd1,dd1), 
              top: this.state.shows[this.state.SelectedItem].top, 
              bottom: this.state.shows[this.state.SelectedItem].bottom,
              option: this.state.shows[this.state.SelectedItem].option 
              });
  
              //swap only 3
              temp.push({ number: dd1.concat(dd2,dd1), 
              top: this.state.shows[this.state.SelectedItem].top, 
              bottom: this.state.shows[this.state.SelectedItem].bottom,
              option: this.state.shows[this.state.SelectedItem].option 
              });
  
              temp.push({ number: dd1.concat(dd1,dd2), 
              top: this.state.shows[this.state.SelectedItem].top, 
              bottom: this.state.shows[this.state.SelectedItem].bottom,
              option: this.state.shows[this.state.SelectedItem].option 
              });
          }
         
 



        }

      }
      else
      {
        temp.push(this.state.rows[i]);
      }

    }

    // console.log(temp);

    this.state.rows = temp;
    this.state.shows = temp;

    this.Snackbar('สร้างกลับเลขเสร็จสิ้น!');
    this.setState({});
    if (this.state.accordionContent[index + 1]) {
      this.state.accordionContent[index + 1].focus();
    }

  }

  handleTop = event => {

   // this.Snackbar(event.keyCode);

    if (event.keyCode === 106) {/* auto rotate number*/
      this.AutoRotageNumber();
     }

    //96-105 is padnumber: 48-57: key number 0-9  37/39 left right arrow
    if (event.keyCode > 31 && event.keyCode !== 110 && event.keyCode !== 107 && event.keyCode !== 39 && event.keyCode !== 37 && (event.keyCode < 48 || event.keyCode > 57) && (event.keyCode < 96 || event.keyCode > 105)) {
      event.preventDefault();
      return false;
    }
    //110 = . copy key
    try {
  

      if (event.keyCode === 110) {

        if (this.state.shows[this.state.SelectedItem].bottom !== '') {
          this.state.shows[this.state.SelectedItem].top = this.state.shows[this.state.SelectedItem].bottom;
          event.preventDefault();
          this.setState({});
          return false;
        }
        else {
          event.preventDefault();
          return false;
        }
      }
    } catch (error) { alert(error); }


    if (event.keyCode === 13) {
      const form = event.target.form;

      const index = Array.prototype.indexOf.call(form, event.target);
      let defaulSkip=2;
      
      
      if(this.state.use_three_bottom===false  && this.state.shows[this.state.SelectedItem].number.length===3)
      {
        defaulSkip=4;
      }
      
      if (form.elements[index + defaulSkip]) {
        form.elements[index + defaulSkip].focus();
      }

      if (event.target.value !== '') {
        if (!this.is_val(event.target.value)) {
          event.preventDefault();
          return false;
        }
      }



      event.preventDefault();
    }
  }

  is_val = (value) => {
    var _ret = false;
    if (value === '') {
      return '';
    }

    try {

      if (!parseInt(value)) {
        this.Snackbar('กรุณาระบุตัวเลข หรือเลขชุดที่บวกกันเท่านั้น เช่น 10,10+20 เป็นต้น');
      }
      else {
        _ret = true;
      }
    }
    catch (error) {
      //console.log(error);
      this.Snackbar(error);
    }
    return _ret;
  }



  handleBottom = event => {
    //this.Snackbar(event.keyCode);
    //96-105 is padnumber: 48-57: key number 0-9  37/39 left right arrow
    if (event.keyCode === 106) {/* auto rotate number*/
      this.AutoRotageNumber();
     }

    if (event.keyCode > 31 && event.keyCode !== 110 && event.keyCode !== 107 && event.keyCode !== 39 && event.keyCode !== 37 && (event.keyCode < 48 || event.keyCode > 57) && (event.keyCode < 96 || event.keyCode > 105)) {
      event.preventDefault();
      return false;
    }

    //110 = . copy key
    try {
      if (event.keyCode === 110) {

        if (this.state.shows[this.state.SelectedItem].top !== '') {
          this.state.shows[this.state.SelectedItem].bottom = this.state.shows[this.state.SelectedItem].top;
          event.preventDefault();
          this.setState({});
          return false;
        }
        else {
          event.preventDefault();
          return false;
        }
      }
    }
    catch (error) { alert(error); }


    if (event.keyCode === 13) {

      if(this.state.shows[this.state.SelectedItem].number.length !== 3)
      {
        var err = '';

        if (this.state.shows[this.state.SelectedItem].number === '') {
          err += " เลขมีค่าว่าง.";
        }
  
   
          if (this.state.shows[this.state.SelectedItem].top === '' && this.state.shows[this.state.SelectedItem].bottom === '') {
            err += " ยอดซื้อ บน ล่าง ต้องระขุอย่างน้อยหนึ่่งค่า.";
          }
        
          
          if(parseInt(this.state.selected_minbuy.text)<= parseInt(this.state.shows[this.state.SelectedItem].top)  )
          {
            err+=' ,ตัวเลข-ขน ระบุมากกว่าหรือเท่ากับ ' + this.state.selected_minbuy.text;
          }
          if(parseInt(this.state.selected_minbuy.text)<= parseInt(this.state.shows[this.state.SelectedItem].bottom)  )
          {
            err+=' ,ตัวเลข-ล่าง ระบุมากกว่าหรือเท่ากับ ' + this.state.selected_minbuy.text;
          }

        if (err !== '') {
          this.Snackbar('พบข้อผิดพลาด! ' + err);
  
          event.preventDefault();
          return false;
        }
  
        const form = event.target.form;
  
        const index = Array.prototype.indexOf.call(form, event.target);
  
          //if two digig
          var skip_control = 6;
          //total control=3*2 becase materia-ui has two controls  index;
    
          if (this.state.SelectedItem === (this.state.shows.length - 1)) {
            this.state.accordionContent[this.state.SelectedItem].style.background = this.colorNormalTwo;
            this.state.shows[this.state.SelectedItem].option='';
            this.inserted(null, this.state.SelectedItem);
          }
          else {
            if (form.elements[index + skip_control]) {
              form.elements[index + skip_control].focus();
            }
          }
        
      
  
        this.state.isChange = true;
        //this.Snackbar('บันทึกข้อมูลแล้ว!');
        this.setState({});
      }
      else
      {
        //three digit
        const form = event.target.form;  
        const index = Array.prototype.indexOf.call(form, event.target);
        if (form.elements[index + 2]) {
          form.elements[index + 2].focus();
        }

        

      }

      event.preventDefault();
    }
  }

  handleOption = event => {
    //this.Snackbar(event.keyCode);
    //96-105 is padnumber: 48-57: key number 0-9  37/39 left right arrow
    if (event.keyCode === 106) {/* auto rotate number*/
      this.AutoRotageNumber();
     }

    if (event.keyCode > 31 && event.keyCode !== 110 && event.keyCode !== 107 && event.keyCode !== 39 && event.keyCode !== 37 && (event.keyCode < 48 || event.keyCode > 57) && (event.keyCode < 96 || event.keyCode > 105)) {
      event.preventDefault();
      return false;
    }

    //110 = . copy key
    try {
      if (event.keyCode === 110) {

        if (this.state.shows[this.state.SelectedItem].top !== '') {
          this.state.shows[this.state.SelectedItem].bottom = this.state.shows[this.state.SelectedItem].top;
          event.preventDefault();
          this.setState({});
          return false;
        }
        else {
          event.preventDefault();
          return false;
        }
      }
    }
    catch (error) { alert(error); }


    if (event.keyCode === 13) {

    

    
     // this.Snackbar(this.state.shows[this.state.SelectedItem].top + '/' + this.state.shows[this.state.SelectedItem].bottom + '/' + this.state.shows[this.state.SelectedItem].option);
     
        var err = '';

        if (this.state.shows[this.state.SelectedItem].number === '') {
          err += " เลขมีค่าว่าง.";
        }
  

          
         if (typeof this.state.shows[this.state.SelectedItem].option === 'undefined') 
         {
            this.state.shows[this.state.SelectedItem].option='';
          
          }
   
          if (this.state.shows[this.state.SelectedItem].top === '' && this.state.shows[this.state.SelectedItem].bottom === '' && this.state.shows[this.state.SelectedItem].option === '') {
            err += " ยอดซื้อ บน ล่าง โต๊ด  ต้องระขุอย่างน้อยหนึ่่งค่า.";
          }
        
          if(parseInt(this.state.selected_minbuy.text)<= parseInt(this.state.shows[this.state.SelectedItem].top)  )
          {
            err+=' ,ตัวเลข-ขน ระบุมากกว่าหรือเท่ากับ ' + this.state.selected_minbuy.text;
          }
          if(parseInt(this.state.selected_minbuy.text)<= parseInt(this.state.shows[this.state.SelectedItem].bottom)  )
          {
            err+=' ,ตัวเลข-ล่าง ระบุมากกว่าหรือเท่ากับ ' + this.state.selected_minbuy.text;
          }
          if(parseInt(this.state.selected_minbuy.text)<= parseInt(this.state.shows[this.state.SelectedItem].option)  )
          {
            err+=' ,ตัวเลข-โต็ด ระบุมากกว่าหรือเท่ากับ ' + this.state.selected_minbuy.text;
          }

        if (err !== '') {
          this.Snackbar('พบข้อผิดพลาด! ' + err);
  
          event.preventDefault();
          return false;
        }

       /* this.Snackbar(this.state.shows[this.state.SelectedItem].top + '/' + this.state.shows[this.state.SelectedItem].bottom + '/' + this.state.shows[this.state.SelectedItem].option);
        event.preventDefault();
        return false;*/

  
        const form = event.target.form;
  
        const index = Array.prototype.indexOf.call(form, event.target);
  
          //if two digig
          var skip_control = 6;
          //total control=3*2 becase materia-ui has two controls  index;
    
          if (this.state.SelectedItem === (this.state.shows.length - 1)) {
            this.state.accordionContent[this.state.SelectedItem].style.background = this.colorNormalTwo;
    
            this.inserted(null, this.state.SelectedItem);
          }
          else {
            if (form.elements[index + skip_control]) {
              form.elements[index + skip_control].focus();
            }
          }
        
      
  
        this.state.isChange = true;
        //this.Snackbar('บันทึกข้อมูลแล้ว!');
        this.setState({});

     

      event.preventDefault();
    }
  }

  handleEnter = event => {
    if (event.keyCode === 13) {
      const form = event.target.form;

      const index = Array.prototype.indexOf.call(form, event.target);

      if (form.elements[index + 2]) {
        form.elements[index + 2].focus();
      }

      event.preventDefault();
    }
  }

  getTooltip = value => {
    var ret = value;
    try {
      if (eval(value)) {
        ret += '=' + eval(value);
      }
    }
    catch (error) { console.log(error); }

    if (ret === "") { ret = 'ค่าว่าง!'; }
    return ret;
  }

  onFormSubmit = e => {
    e.preventDefault();

  }

  onSaved(outs){
  
  //this.setState({ open_texts: false,shows:outs,rows:outs });
  this.state.open_texts=false;
  this.state.shows=outs;
  this.state.rows=outs;

  this.saveAll();
  }

  render() {

    /*
    this.setState({ 
      use_group: localStorage.getItem('use_group')===null?false:localStorage.getItem('use_group'),
      use_three_bottom: localStorage.getItem('use_three_bottom')===null?false:localStorage.getItem('use_three_bottom')
    });*/
    
   
    const { redirectToReferrer } = this.state;

    if (redirectToReferrer === true) {
      return <Redirect to={{ pathname: '/home' }} />
    }



    const { classes, theme } = this.props

    this.setRowsBlank();

  
    return (



      <form onSubmit={this.onFormSubmit}>

        <div className={classes.paper}  >





          <Grid container spacing={3} mt={3}      >


            <Grid item spacing={1} sm={12} md={4} xs={12}  >
              <Breadcrumbs aria-label="breadcrumb">
                <Link color="inherit" to="/home" style={{ color: 'inherit', textDecoration: 'inherit' }} > Home  </Link>
                <Typography color="textPrimary">{this.state.title}</Typography>
              </Breadcrumbs>
            </Grid>

            <Grid item spacing={1} sm={12} md={2} xs={12}  >
               
                
               <Grid item xs={12} spacing={1}>
                 <FormControlLabel control={
                   <Switch color="primary"
                     checked={this.state.use_three_bottom}
                     onChange={(e) => {   
                       
                      this.setState({ use_three_bottom: e.target.checked });
                      localStorage.setItem('use_three_bottom',   e.target.checked ); 
                    }
                     
                    }
                   />
                 } label="ใช้งาน สามตัวล่าง" />
               </Grid>

             
             </Grid>
             <Grid item spacing={1} sm={12} md={2} xs={12}  >
               
                
               <Grid item xs={12} spacing={1}>
                 <FormControlLabel control={
                   <Switch color="primary"
                     checked={this.state.use_group}
                     
                     onChange={(e) => {   
                    
                     
                      this.setState({ use_group: e.target.checked });
                      localStorage.setItem('use_group', e.target.checked ); 
                    }
                     
                    }
                   />
                 } label="ใช้งานกลุ่ม" />
               </Grid>

             
             </Grid>


             <Grid item spacing={1} sm={12} md={2} xs={12}  >
              <Autocomplete
                id="cbo_minrow"
                disabled={this.state.editable?true:false}
                options={this.state.minrows}
                getOptionLabel={(option) => option.text}
                renderInput={(params) => <TextField {...params} label="ขั้นต่ำแถว" variant="outlined" />}
                onChange={(event, value) =>{ 
                  if(value!=='' && value!==null)
                  {
                   // this.state.selected_minrow= { text: value.text  };
                    localStorage.setItem('selected_minrow', value.text); 
                   
                   // this.createMinrow(); ,shows:this.state.rows
                    
                    this.setState({selected_minrow:value }); 
                  }
                
                   
                  }}
                value={this.state.selected_minrow}
              />
            </Grid>

            <Grid item spacing={1} sm={12} md={2} xs={12}  >
              <Autocomplete
                id="cbo_minbuy"
                
                options={this.state.minbuys}
                getOptionLabel={(option) => option.text}
                renderInput={(params) => <TextField {...params} label="สูงสุดเงินซื้อ" variant="outlined" />}
                onChange={(event, value) =>{ 
                if(value!=='' && value!==null)
                {
                 // this.state.selected_minrow= { text: value.text  };
               
                  localStorage.setItem('selected_minbuy', value.text); 
                  
                  this.setState({selected_minbuy:value }); 
                }
              
                 
                }}
                value={this.state.selected_minbuy}
              />
            </Grid>


            <Grid item sm={2} md={2} xs={12} spacing={1}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  disabled={window.admin_flag==='Y'?false:true}
                  inputVariant="outlined"
                  format="dd/MM/yyyy"
                  value={this.state.selected_date}
                  onChange={(date) => { try { if (this.confirmSave()) { this.state.selected_date = date; localStorage.setItem('date', date); this.refresh(); } } catch (error) { console.log(error); } }}
                  label="วันทึ่"
                />
              </MuiPickersUtilsProvider>
            </Grid>

            <Grid item sm={3} md={3} xs={12} spacing={1}>
              <Autocomplete
                id="cbo_sender"
                options={this.state.senders}
                autoFocus
                disabled={window.admin_flag==='Y'?false:true}
                //  getOptionLabel={(option) => option.sender_name}
                getOptionLabel={(option) => { return option.sender_name; }}
                renderInput={(params) => <TextField {...params} label="ผู้ส่ง" variant="outlined" />}
                onChange={(event, value) => { if (this.confirmSave()) { this.state.selected_sender = value; this.refresh(); } }}
                value={this.state.selected_sender}
              />
            </Grid>
            <Grid item sm={3} md={3} xs={12} spacing={1}>
              <Autocomplete
                id="cbo_group"
                disabled={this.state.use_group===true?false:true}
                options={this.state.tickets}
                getOptionLabel={(option) => option.text}
                renderInput={(params) => <TextField {...params} label="กลุ่ม" variant="outlined" />}
                onChange={(event, value) => { if (this.confirmSave()) { this.state.selected_group = value; this.refresh(); } }}
                value={this.state.selected_group}
              />
            </Grid>
            <Grid item sm={3} md={3} xs={12} spacing={1}>
              <Autocomplete
                id="cbo_ticket"
                options={this.state.tickets}
                getOptionLabel={(option) => option.text}
                renderInput={(params) => <TextField {...params} label="ชุด" variant="outlined" />}
                onChange={(event, value) => { 
                  if (this.confirmSave()) { 
                    this.state.selected_ticket = value; this.refresh(); 
                  } 
                }}
                value={this.state.selected_ticket}
              />
            </Grid>

            <Grid item sm={1} md={1} xs={12} spacing={1}>


              <IconButton size="medium" aria-label="plus" onClick={(e) => { this.confirmSave(); this.getTicket(); }}>
                <Icon color="default">search</Icon>
              </IconButton>

            </Grid>

            <Grid container spacing={1} className={this.state.editable ? classes.hide : ''} >

              <Typography component="h1" variant="h5" style={{ margin: '20px' }}>
                กรุณาระบุ ผุู้ส่ง/กลุ่ม/ชุด
                </Typography>
            </Grid>



            <Grid container spacing={1} className={!this.state.editable ? classes.hide : ''} >


              <Grid item spacing={1} sm={12} xs={12} justify="flex-end" >


                <Grid item style={{ margin: '5px' }}>

                  <TextField label="กรองข้อมูลที่นี้." value={this.state.search}
                    onChange={this.SearchChanged} variant="outlined" fullWidth dense />


                </Grid>

              </Grid>


              <Typography component="h4" variant="h5" style={{ margin: '5px' }}>
                ข้อมูลการบันทึกนี้ เป็นข้อมูลชั่วคราว กรุณากดบันทึกปุ่ม [บันทึก] ทุกครั้งก่อนปิด หรือ เปลี่ยนขุดข้อมูล.
                <br></br>
                กดปุ่ม * (ดอกจัน) ในช่องจำนวนเงินเพื่อสร้างการกลับเลขแบบอัตโนมัติ.
                </Typography>


              {this.state.shows.map((row, index) => (
                // <Entrys row={row} action={index!==0 && index!==this.state.rows.length-1  ?true:false} saved={(e)=>{ this.saved(index); this.setState({  });  }}  deleted ={(e)=>{ this.deleted(index);   }}/>


                <Grid container spacing={1} mt={1}      >

                  <Grid item sm={1} md={1} xs={12} spacing={1} >

                  
                          <TextField
                          inputRef={accordionContent => this.state.accordionContent[index] = accordionContent}
                          margin="dense"
                          label="เลข"
                          variant="outlined"
                          type="number"
                          value={row.number}
                          // style={{background:'rgba(100, 149, 237,0.5)'}}
                          onFocus={(e) => { this.setState({ SelectedItem: index }); }}
                          onChange={(e) => { if ((e.target.value.length > 3 || e.target.value < 0)) { this.Snackbar('โปรดระบุตัวเลข มากกว่า 0 และไม่มากกว่า 3 ตัว.'); return false; } const item = this.state.rows[index]; item.number = e.target.value; this.setState({}); }}
                          onKeyDown={this.handleNumber}
    
                          fullWidth
                        />

                    
                   
                  </Grid>
                  
                  <Grid item sm={9} md={9} xs={12} container spacing={1} >

                  <Grid item sm={row.number.length === 3 ?4:6} md={row.number.length === 3 ?4:6} xs={12} spacing={1} >

                    <Tooltip title={<h2 style={{ padding: "5px" }} >{this.getTooltip(this.state.shows[index].top)}</h2>} placement="bottom-start" arrow    >

                 
                      
                          <TextField
                          onFocus={(e) => { this.setState({ SelectedItem: index }); }}
                          
                         
  
                          margin="dense"
                          label={row.number.length === 3 ? "สามตัวตรง(บาท)" : "สองตัวบน(บาท)"}
                          variant="outlined"
                          type="text"
                          value={row.top}
                          onChange={(e) => { if (e.target.value !== '') { if (!this.is_val(e.target.value)) { return false; } }; const item = this.state.rows[index]; item.top = e.target.value; this.setState({}); }}
                          //  onChange={(e) => { this.row.top = e.target.value;this.setState({ }); }}
                          onKeyDown={this.handleTop}
  
  
                          fullWidth
                        />
                     
                    
                    </Tooltip>

                  </Grid>

                  <Grid item sm={row.number.length === 3 ?4:6} md={row.number.length === 3 ?4:6} xs={12} spacing={1}>
                    <Tooltip title={<h2 style={{ padding: "5px" }} >{this.getTooltip(this.state.shows[index].bottom)}</h2>} placement="bottom-start" arrow  >
                      <TextField
                        onFocus={(e) => { this.setState({ SelectedItem: index }); }}
                        margin="dense"
                        disabled={this.state.use_three_bottom===false && row.number.length === 3 ?true:false}
                        label={row.number.length === 3 ? "สามตัวล่าง(บาท)" : "สองตัวล่าง(บาท)"}
                        variant="outlined"
                        type="text"
                        value={row.bottom}
                        onChange={(e) => { if (e.target.value !== '') { if (!this.is_val(e.target.value)) { return false; } }; const item = this.state.rows[index]; item.bottom = e.target.value; this.setState({}); }}
                        //   onChange={(e) => { this.row.bottom = e.target.value;this.setState({ });  }}
                        onKeyDown={this.handleBottom}

                        fullWidth
                      />
                    </Tooltip>
                  </Grid>

                  { row.number.length === 3 ? 
                  
                  
                  <Grid item sm={row.number.length === 3 ?4:6} md={row.number.length === 3 ?4:6} xs={12} spacing={1}>
                  <Tooltip title={<h2 style={{ padding: "5px" }} >{this.getTooltip(this.state.shows[index].bottom)}</h2>} placement="bottom-start" arrow  >
                    <TextField
                      onFocus={(e) => { this.setState({ SelectedItem: index }); }}
                      margin="dense"
                      label={row.number.length === 3 ? "สามตัวโต๊ด(บาท)" : "สองตัวโต๊ด(บาท)"}
                      variant="outlined"
                      type="text"
                      value={row.option}
                      onChange={(e) => { if (e.target.value !== '') { if (!this.is_val(e.target.value)) { return false; } }; const item = this.state.rows[index]; item.option = e.target.value; this.setState({}); }}
                      //   onChange={(e) => { this.row.bottom = e.target.value;this.setState({ });  }}
                      onKeyDown={this.handleOption}

                      fullWidth
                    />
                  </Tooltip>
                </Grid>

                   : null }

                

                  </Grid>


                   
                  <Grid item sm={2} md={2} xs={12}   >

                    <IconButton size="small" aria-label="plus" onClick={(e) => { this.inserted(e, index); }}>
                      <Icon color="default">add_circle</Icon>
                    </IconButton>

                    {index !== this.state.rows.length - 1 &&
                      (
                        <IconButton size="small" aria-label="up" onClick={() => { this.up(index); }}>
                          <Icon color="default">keyboard_arrow_up</Icon>
                        </IconButton>
                      )}
                    {index < this.state.rows.length - 1 &&
                      (
                        <IconButton size="small" aria-label="down" onClick={() => { this.down(index); }}>
                          <Icon color="default">keyboard_arrow_down</Icon>
                        </IconButton>
                      )}

                    {index !== this.state.rows.length - 1 &&
                      (
                        <IconButton size="small" aria-label="delete" onClick={() => { this.deleted(index); }}>
                          <Icon color="secondary">delete_outline</Icon>
                        </IconButton>
                      )}
                  </Grid>
                  





                </Grid>


              ))}





            </Grid>


            <Box xs={12} spacing={1} mt={3} className={!this.state.editable ? classes.hide : ''}>

           
            
              {this.state.locked===false && (<Fab title="บันทีกข้อมูล"  aria-label="save" className={classes.fab_save} onClick={this.saveAll} >
                <Icon color="primary" >save_circle</Icon>
              </Fab>)}   

              <Fab title="ข้อมูลข้อความ" aria-label="text" className={classes.fab_text} onClick={(e) => { this.setState({ open_texts: true }); }}  >
                <Icon color="primary"  >assignment</Icon>
              </Fab>

              <Fab  title="รายงาน" aria-label="report" className={classes.fab_report} onClick={(e) => { this.setState({ open_reports: true }); }}  >
                <Icon color="primary" >print</Icon>
              </Fab>


            </Box>
          </Grid>

        </div>

        <Dialog
          onClose={() => { this.setState({ open_reports: false }); }}
          aria-labelledby="รายงานสรุป"
          open={this.state.open_reports}
          fullScreen
        >

          <AppBar className={classes.appBar}>
            <Toolbar variant="dense"  >
              <IconButton edge="start" color="inherit" onClick={() => { this.setState({ open_reports: false }); }} aria-label="close">
                <Icon color="secondary">close</Icon>
              </IconButton>
              <Typography variant="h6" className={classes.title}>รายงานสรุป</Typography>


            </Toolbar>
          </AppBar>

          <DialogContent     >
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"

            >
              <TicketDetail messages={this.state} />


            </Grid>
          </DialogContent>


        </Dialog>


        <Dialog
          onClose={() => { this.setState({ open_datas_list: false }); }}
          aria-labelledby="รายการข้อมูล"
          open={this.state.open_datas_list}

        >

          <AppBar className={classes.appBar}>
            <Toolbar variant="dense"  >
              <IconButton edge="start" color="inherit" onClick={() => { this.setState({ open_datas_list: false }); }} aria-label="close">
                <Icon color="secondary">close</Icon>
              </IconButton>
              <Typography variant="h6" className={classes.title}>รายการข้อมูล</Typography>


            </Toolbar>
          </AppBar>

          <DialogContent>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"

            >


              <Grid item spacing={12} sm={12} xs={12}  >


                <TableContainer component={Paper}>


                  <Table className={classes.table} size="small" aria-label="a dense table" >
                    <TableHead>

                      <TableRow>
                        <TableCell  >เลือก</TableCell>

                        <TableCell align="left">ผุู้ส่ง</TableCell>
                        <TableCell align="left">กลุ่ม</TableCell>
                        <TableCell align="left">ชุด</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.lists_tickets.map((row, index) => (

                        <TableRow key={row.id}>
                          <TableCell component="th" scope="row"  >


                            <IconButton aria-label="edit" onClick={() => { this.selected_tiket(row) }}>
                              <Icon color="primary">check</Icon>
                            </IconButton>


                          </TableCell>

                          <TableCell align="left">{row.sender_id} / {row.sender_name}</TableCell>
                          <TableCell align="left">{row.group_id} </TableCell>
                          <TableCell align="left">{row.ticket_id} </TableCell>

                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>

              </Grid>

            </Grid>

          </DialogContent>

        </Dialog>

        <Dialog
          onClose={() => { this.setState({ open_texts: false }); }}
          aria-labelledby="จัดการข้อมูลแบบข้อความ"
          open={this.state.open_texts}
          fullScreen

        >

          <AppBar className={classes.appBar}>
            <Toolbar variant="dense"  >
              <IconButton edge="start" color="inherit" onClick={() => { this.setState({ open_texts: false }); }} aria-label="close">
                <Icon color="secondary">close</Icon>
              </IconButton>
              <Typography variant="h6" className={classes.title}>จัดการข้อมูลแบบข้อความ</Typography>

             

            </Toolbar>
          </AppBar>

          <DialogContent>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"

            >

              <TextInput messages={this.state} onSaved={this.onSaved.bind(this)} />
              

            </Grid>

          </DialogContent>

        </Dialog>


        <Snackbar open={this.state.Snackbar} autoHideDuration={4000} onClose={() => { this.setState({ Snackbar: false }); }}>
          <Alert onClose={() => { this.setState({ Snackbar: false }); }} severity="info">{this.state.message}</Alert>
        </Snackbar>


      </form>
    );
  }
}




export default withStyles(useStyles, { withTheme: true })(transactons)
