export const store = {
    
    _email: '',
    _token: '',
    _company_id:'',
    _user_id:'',
    _password:'',
    isLogged () {
      return this.token
    },
  
    
  
    set email (str) {
      this._email = str
      localStorage.setItem('email',str)
    },
    get email () {
      return this._email || localStorage.getItem('email')
    },
  
    set password (str) {
      this._password = str
      localStorage.setItem('password',str)
    },
    get password () {
      return this._password || localStorage.getItem('password')
    },

    set token (str) {
      this._token = str
      localStorage.setItem('token',str)
    },
    get token () {
      return this._token || localStorage.getItem('token')
    },
  
    set company_id (str) {
      this._company_id = str
      localStorage.setItem('company_id',str)
    },
    get company_id () {
      return this._company_id || localStorage.getItem('company_id')
    },

    set user_id (str) {
      this._user_id = str
      localStorage.setItem('user_id',str)
    },
    get user_id () {
      return this._user_id || localStorage.getItem('user_id')
    },

   get url_addition () {
      return ""
    },

  }