import React, { Component } from "react";
//, { Component } 
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from "react-router-dom";

import { fade, withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Box from '@material-ui/core/Box';
import API from "../utils/API";


import Icon from '@material-ui/core/Icon';


import TextField from '@material-ui/core/TextField';



import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import Button from '@material-ui/core/Button';

import {store} from '../utils/store';

//Dialog
import Dialog from '@material-ui/core/Dialog';
//import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
//import DialogContentText from '@material-ui/core/DialogContentText';
//import DialogTitle from '@material-ui/core/DialogTitle';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';

//calendar picker
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import {
  DatePicker,
//  TimePicker,
// DateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

import Autocomplete from '@material-ui/lab/Autocomplete';
 
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';


//resultrewardsender.
import ResultRewardSender from "./resultrewardsender";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  paper: {
    margin: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    flexGrow: 1,
  },
  table: {  },
  tableWrapper: {
    width:'100%',
    overflow: 'auto'
  },
 
});




class Result extends Component {
  //export default function Products() {

  constructor(props) {
    super(props);
   // this.PageChanged = this.PageChanged.bind(this);

    if ( window.user_id===undefined || window.user_id===null)
    {
        window.location='/signin';
    }

    let user_id='';
    let company_id='';

    if(window.user_id)
    {
      user_id=window.user_id;
    }
   
    if(window.company_id)
    {
      company_id=window.company_id;
    }

    let top_number_reward=localStorage.getItem('top_number_reward');
    let bottom_number_reward=localStorage.getItem('bottom_number_reward');

    let bottom_option_number_reward1=localStorage.getItem('bottom_option_number_reward1');
    let bottom_option_number_reward2=localStorage.getItem('bottom_option_number_reward2');
    let bottom_option_number_reward3=localStorage.getItem('bottom_option_number_reward3');
    let bottom_option_number_reward4=localStorage.getItem('bottom_option_number_reward4');


    let two_top_reward=localStorage.getItem('two_top_reward');
    let two_bottom_reward=localStorage.getItem('two_bottom_reward');

    let three_direct_reward=localStorage.getItem('three_direct_reward');
    let three_bottom_reward=localStorage.getItem('three_bottom_reward1');
    let three_option_reward=localStorage.getItem('three_option_reward');

    let one_top_reward=localStorage.getItem('one_top_reward');
    let one_bottom_reward=localStorage.getItem('one_bottom_reward');

    let share_percent=localStorage.getItem('share_percent');

    this.state = {
      url_addition : store.url_addition,
      company_id: company_id,
      user_id:user_id,
      selected_date : localStorage.getItem('date')===null? new Date():localStorage.getItem('date') ,
      selected_sender:null,
      sender_name:'',
      sender_id:'',
      top_number_reward:top_number_reward===null?999:top_number_reward,
      bottom_number_reward:bottom_number_reward===null?99:bottom_number_reward,

      bottom_option_number_reward1:bottom_option_number_reward1===null?999:bottom_option_number_reward1,
      bottom_option_number_reward2:bottom_option_number_reward2===null?999:bottom_option_number_reward2,
      bottom_option_number_reward3:bottom_option_number_reward3===null?999:bottom_option_number_reward3,
      bottom_option_number_reward4:bottom_option_number_reward4===null?999:bottom_option_number_reward4,

       two_top_reward: two_top_reward===null?70:two_top_reward,
      two_bottom_reward: two_bottom_reward===null?70:two_bottom_reward,

      three_direct_reward: three_direct_reward===null?400:three_direct_reward,
      three_bottom_reward: three_bottom_reward===null?100:three_bottom_reward,
      three_option_reward: three_option_reward===null?80:three_option_reward,

      one_top_reward: one_top_reward===null?3:one_top_reward,
      one_bottom_reward: one_bottom_reward===null?4:one_bottom_reward,
      share_percent: share_percent===null?25:share_percent,

      

      chip_total:"รวมขายทั้งหมด 0 บาท",
      chip_share_percent:"รวมส่วนแบ่งการขายทั้งหมด 0 บาท",
      chip_total_reward:"รวมถูกทั้งหมด 0 บาท",
      chip_total_profit:"รวมกำไรทั้งหมด 0 บาท",

      title: "คำนวณกำไร",
      dataset: 'senders',
     
      waitting: false,
      rows: [],
      selectedItems: {},
     
      search: "",
     
      Snackbar: false,
      message: "",
      open_reports: false,

    };
    this.fileInput = React.createRef();
    this.forms = React.createRef();
    this.maxdate();
    this.lookupTable();
   // this.refresh();

     
  }

  Snackbar = (message) => {
    this.setState({ Snackbar: true, message: message });
  };


/*
  SearchChanged = (e) => {
    this.setState({ search: e.target.value });


    if(this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.refresh();
    }, 400);



  };*/

  /*
  PageChanged = (event, value) => {
    this.refresh(value);
  };*/

  maxdate = () => {
    API.get(this.state.url_addition + 'api/maxdate?company_id=' + this.state.company_id )
    .then((res) => {
       
      if (res.data.error) {
        this.Snackbar(res.data.error);
        this.state.locked=true;
        window.locked=true;
      }
      else
      {
         this.setState({ selected_date:res.data.datas});
      }

    }).catch((error) => {
      this.Snackbar(error)
     
    });

}

  lookupTable = () => {
    API.get(this.state.url_addition + 'lookup?data=senders&company_id=' + this.state.company_id + '&company_id_parent=' + this.state.company_id_parent)
      .then((res) => {
        let items = [];
        let last;

        res.data.datas.forEach(element => {
          if(element.sender_id===window.user_id)
          {
            last={ sender_name: element.sender_name, sender_id: element.sender_id };
          }
         
          items.push({ sender_name: element.sender_name, sender_id: element.sender_id });
        });

        if(window.admin_flag==='N')
        {
          this.setState({ senders: items,selected_sender:last });
          this.refresh();
        }
        else
        {
          this.setState({ senders: items });
        }
       
        this.setState({ senders: items });


      }).catch((error) => {
        console.log(error)
      });

  };


  saveSettings=()=>{

    localStorage.setItem('two_top_reward', this.state.two_top_reward);
    localStorage.setItem('two_bottom_reward', this.state.two_bottom_reward);

    localStorage.setItem('bottom_option_number_reward1', this.state.bottom_option_number_reward1);
    localStorage.setItem('bottom_option_number_reward2', this.state.bottom_option_number_reward2);
    localStorage.setItem('bottom_option_number_reward3', this.state.bottom_option_number_reward3);
    localStorage.setItem('bottom_option_number_reward4', this.state.bottom_option_number_reward4);
    

    localStorage.setItem('three_direct_reward', this.state.three_direct_reward);
    localStorage.setItem('three_option_reward', this.state.three_option_reward);
    localStorage.setItem('one_top_reward', this.state.one_top_reward);
    localStorage.setItem('one_bottom_reward', this.state.one_bottom_reward);
  
    localStorage.setItem('top_number_reward', this.state.top_number_reward);
    localStorage.setItem('bottom_number_reward', this.state.bottom_number_reward);
    localStorage.setItem('share_percent', this.state.share_percent);


   }

  refresh = () => {
    this.saveSettings();

    var company_id='';
    var sender_id='';
    company_id=this.state.company_id;
   if(this.state.selected_sender!==null)
   {
     sender_id=this.state.selected_sender.sender_id.toString();
   }
  
    
    var param='result?company_id=' + company_id;

    param+= '&company_id_parent=' + window.company_id_parent;

    param+="&date=" + this.formatDate(this.state.selected_date.toString()) ;
 
    param+="&sender=" + sender_id;

    param+="&top_number_reward=" + this.state.top_number_reward;
    param+="&bottom_number_reward=" + this.state.bottom_number_reward;

    param+="&bottom_option_number_reward1=" + this.state.bottom_option_number_reward1;
    param+="&bottom_option_number_reward2=" + this.state.bottom_option_number_reward2;
    param+="&bottom_option_number_reward3=" + this.state.bottom_option_number_reward3;
    param+="&bottom_option_number_reward4=" + this.state.bottom_option_number_reward4;

    

    param+="&two_top_reward=" + this.state.two_top_reward;
    param+="&two_bottom_reward=" + this.state.two_bottom_reward;
    param+="&three_direct_reward=" + this.state.three_direct_reward;
    param+="&three_bottom_reward=" + this.state.three_direct_reward;
    param+="&three_option_reward=" + this.state.three_option_reward;
    param+="&one_top_reward=" + this.state.one_top_reward;
    param+="&one_bottom_reward=" + this.state.one_bottom_reward;
   
    param+="&two_top_limit=" + this.state.two_top_limit;
    param+="&two_bottom_limit=" + this.state.two_bottom_limit;
    param+="&three_direct_limit=" + this.state.three_direct_limit;
    param+="&three_option_limit=" + this.state.three_option_limit
    param+="&one_top_limit=" + this.state.one_top_limit;
    param+="&one_bottom_limit=" + this.state.one_bottom_limit;
    
    param+="&search=" + this.state.search +"";

    API.get(this.state.url_addition   + param )
    .then((res) => {
      if(res.data.error===null)
      {
        this.setState({rows: res.data.datas });
      }
      else
      {
        this.Snackbar(res.data.error);
      }

    }).catch((error) => {
      console.log(error)
      this.Snackbar('กรูณาเข้าสู่ระบบอีกครั้ง ' + error);
    });
  }

  formatDate=(date)=>{
    var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

    if (month.length < 2) 
    month = '0' + month;
    if (day.length < 2) 
    day = '0' + day;

    return [year, month, day].join('-');
  } 

   formatNumber = inputNumber => {
    let formetedNumber=(Number(inputNumber)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    let splitArray=formetedNumber.split('.');
    if(splitArray.length>1){
      formetedNumber=splitArray[0];
    }

   /* if( isNaN(formetedNumber) )
    {
      formetedNumber=0;
    }*/

    return(formetedNumber);
  };

  rowSelected=(item)=>
  {
    if(item!==null)
    {
     // let temp=window.company_id_parent!==''?item.sender_id:item.company_id;

      this.setState({sender_name:item.sender_name,sender_id:item.sender_id  , open_reports: true });
      //this.Snackbar('You are click :' + item.sender_id);
    }
  }
  componentDidMount() {
    //  alert('componentDidMount');

  }

  render() {
    const { classes } = this.props;
    var datas=this.state.rows;

    
    var total=  datas.reduce((a,v) =>  a = a + parseInt(v.data_sum) , 0 ) ;
    var total_reward=  datas.reduce((a,v) =>  a = a + parseInt(v.data_sum_reward) , 0 ) ;
   
    var share_percent =total*(this.state.share_percent/100.00);

    var total_profit=(parseInt(total)-parseInt(share_percent)-parseInt(total_reward) );

    this.state.chip_total="รวมขายทั้งหมด " + this.formatNumber(total) + " บาท";
    this.state.chip_share_percent="รวมส่วนแบ่งการขายทั้งหมด " + this.formatNumber( share_percent ) + " บาท";
    this.state.chip_total_reward="รวมถูกทั้งหมด " + this.formatNumber(total_reward) + " บาท";
    this.state.chip_total_profit="รวมกำไรทั้งหมด " + this.formatNumber( total_profit ) + " บาท";

    var two_top=  datas.reduce((a,v) =>  a = a + parseInt(v.two_top) , 0 ) ;
    var two_top_reward=  datas.reduce((a,v) =>  a = a + parseInt(v.two_top_reward) , 0 ) ;
    var two_bottom=  datas.reduce((a,v) =>  a = a + parseInt(v.two_bottom) , 0 ) ;
    var two_bottom_reward=  datas.reduce((a,v) =>  a = a + parseInt(v.two_bottom_reward) , 0 ) ;


    var three_direct=  datas.reduce((a,v) =>  a = a + parseInt(v.three_direct) , 0 ) ;
    var three_direct_reward=  datas.reduce((a,v) =>  a = a + parseInt(v.three_direct_reward) , 0 ) ;

    var three_bottom=  datas.reduce((a,v) =>  a = a + parseInt(v.three_direct) , 0 ) ;
    var three_bottom_reward=  datas.reduce((a,v) =>  a = a + parseInt(v.three_bottom_reward) , 0 ) ;

    var three_option=  datas.reduce((a,v) =>  a = a + parseInt(v.three_option) , 0 ) ;
    var three_option_reward=  datas.reduce((a,v) =>  a = a + parseInt(v.three_option_reward) , 0 ) ;

    var one_top=  datas.reduce((a,v) =>  a = a + parseInt(v.one_top) , 0 ) ;
    var one_top_reward=  datas.reduce((a,v) =>  a = a + parseInt(v.one_top_reward) , 0 ) ;
    var one_bottom=  datas.reduce((a,v) =>  a = a + parseInt(v.one_bottom) , 0 ) ;
    var one_bottom_reward=  datas.reduce((a,v) =>  a = a + parseInt(v.one_bottom_reward) , 0 ) ;
/*
    var data_value1=  datas.reduce((a,v) =>  a = a + parseInt(v.data_value1) , 0 ) ;
    var reward_value1=  datas.reduce((a,v) =>  a = a + parseInt(v.reward_value1) , 0 ) ;
    var data_value2=  datas.reduce((a,v) =>  a = a + parseInt(v.data_value2) , 0 ) ;
    var reward_value2=  datas.reduce((a,v) =>  a = a + parseInt(v.reward_value2) , 0 ) ;

    var data_value3=  datas.reduce((a,v) =>  a = a + parseInt(v.data_value3) , 0 ) ;
    var reward_value3=  datas.reduce((a,v) =>  a = a + parseInt(v.reward_value3) , 0 ) ;*/

    var data_sum=  datas.reduce((a,v) =>  a = a + parseInt(v.data_sum) , 0 ) ;
    var data_sum_reward=  datas.reduce((a,v) =>  a = a + parseInt(v.data_sum_reward) , 0 ) ;


    return (
      <div className={classes.root} >
        <Box mb={3}    >
          <Grid container spacing={1}    >

            <Grid item spacing={1} sm={9} xs={12}  >
              <Breadcrumbs aria-label="breadcrumb">
                <Link color="inherit" to="/home" style={{ color: 'inherit', textDecoration: 'inherit'}} > Home  </Link>
                <Typography color="textPrimary">{this.state.title}</Typography>
              </Breadcrumbs>
            </Grid>
           
            

          </Grid>
        </Box>

        <Grid container spacing={1}   className={!this.state.editable?classes.hide:''} >


            <Grid item sm={6} md={6} xs={12}  spacing={1}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker 
            fullWidth
            disabled={window.admin_flag==='Y'?false:true}
            inputVariant="outlined"
            format="dd/MM/yyyy"
            value={this.state.selected_date} 
            onChange={ (date)=>{  localStorage.setItem('date',date); this.setState({selected_date:date});     }    } 
            label="วันทึ่"
            />
            </MuiPickersUtilsProvider>
            </Grid>


            <Grid item sm={6} md={6} xs={12}  spacing={1}>
            <Autocomplete
            id="cbo_sender"
            options={this.state.senders}
            autoFocus
            disabled={window.admin_flag==='Y'?false:true}
            //  getOptionLabel={(option) => option.sender_name}
            getOptionLabel={(option) => { return option.sender_name; }}
            renderInput={(params) => <TextField {...params} label="ผู้ส่ง" variant="outlined" />}
            onChange={(event, value) =>{   this.setState({selected_sender:value}); }   }
            value={this.state.selected_sender}
            />
            </Grid>
              
            <Grid item sm={6} md={6} xs={12}  spacing={1}>

            <TextField
            variant="outlined" 
            value={this.state.top_number_reward}
            onChange={(e) => { this.setState({ top_number_reward:  e.target.value }); }}
            maxlength="3"
            margin="dense"
            label="เลขบนที่ออก"
            type="number"
            fullWidth
            margin="normal"
            inputProps={{style: {fontSize: 40}}} // font size of input text
            InputLabelProps={{style: {fontSize: 22}}} // font size of input label
            />
            </Grid>

            <Grid item sm={6} md={6} xs={12}  spacing={1}>

            <TextField
            variant="outlined" 
            value={this.state.bottom_number_reward}
            onChange={(e) => { this.setState({ bottom_number_reward:  e.target.value }); }}
            maxlength="3"
            margin="dense"
            label="เลขล่างที่ออก"
            type="number"
            fullWidth
            margin="normal"
            inputProps={{style: {fontSize: 40}}} // font size of input text
            InputLabelProps={{style: {fontSize: 22}}} // font size of input label
            />
            </Grid>
 

<Grid item sm={12} md={12} xs={12}  spacing={1} container>

            <Grid item sm={3} md={3} xs={12}  spacing={1}>
            <TextField
            variant="outlined" 
            value={this.state.bottom_option_number_reward1}
            onChange={(e) => { this.setState({ bottom_option_number_reward1:  e.target.value }); }}
            maxlength="3"
            margin="dense"
            label="สามตัวล่างที่1"
            type="number"
            fullWidth
            margin="normal"
            inputProps={{style: {fontSize: 40}}} // font size of input text
            InputLabelProps={{style: {fontSize: 22}}} // font size of input label
            />
            </Grid>

            <Grid item sm={3} md={3} xs={12}  spacing={1}>
            <TextField
            variant="outlined" 
            value={this.state.bottom_option_number_reward2}
            onChange={(e) => { this.setState({ bottom_option_number_reward2:  e.target.value }); }}
            maxlength="3"
            margin="dense"
            label="สามตัวล่างที่2"
            type="number"
            fullWidth
            margin="normal"
            inputProps={{style: {fontSize: 40}}} // font size of input text
            InputLabelProps={{style: {fontSize: 22}}} // font size of input label
            />
            </Grid>

            <Grid item sm={3} md={3} xs={12}  spacing={1}>
            <TextField
            variant="outlined" 
            value={this.state.bottom_option_number_reward3}
            onChange={(e) => { this.setState({ bottom_option_number_reward3:  e.target.value }); }}
            maxlength="3"
            margin="dense"
            label="สามตัวล่างที่3"
            type="number"
            fullWidth
            margin="normal"
            inputProps={{style: {fontSize: 40}}} // font size of input text
            InputLabelProps={{style: {fontSize: 22}}} // font size of input label
            />
            </Grid>

            <Grid item sm={3} md={3} xs={12}  spacing={1}>
            <TextField
            variant="outlined" 
            value={this.state.bottom_option_number_reward4}
            onChange={(e) => { this.setState({ bottom_option_number_reward4:  e.target.value }); }}
            maxlength="3"
            margin="dense"
            label="สามตัวล่างที่4"
            type="number"
            fullWidth
            margin="normal"
            inputProps={{style: {fontSize: 40}}} // font size of input text
            InputLabelProps={{style: {fontSize: 22}}} // font size of input label
            />
            </Grid>



 </Grid>

 <Grid item sm={12} md={12} xs={12}  spacing={1} container>
            <Grid item sm={1} md={1} xs={12}  spacing={1}>

                    <TextField
                    variant="outlined" 
                    value={this.state.two_top_reward}
                    onChange={(e) => { this.setState({ two_top_reward:  e.target.value }); }}
                    maxlength="50"
                    margin="dense"
                    label="รางวัลสองตัวบน"
                    type="number"
                    fullWidth
                    />

            </Grid>

            <Grid item sm={1} md={1} xs={12}  spacing={1}>
            
                    <TextField
                    variant="outlined" 
                    value={this.state.two_bottom_reward}
                    onChange={(e) => { this.setState({ two_bottom_reward:  e.target.value }); }}
                    maxlength="50"
                    margin="dense"
                    label="รางวัลสองตัวล่าง"
                    type="number"
                    fullWidth
                    />
            </Grid>

            <Grid item sm={1} md={1} xs={12}  spacing={1}>
            
            <TextField
            variant="outlined" 
            value={this.state.three_direct_reward}
            onChange={(e) => { this.setState({ three_direct_reward:  e.target.value }); }}
            maxlength="50"
            margin="dense"
            label="รางวัลสามตัวบน"
            type="number"
            fullWidth
            />
            </Grid>

            <Grid item sm={1} md={1} xs={12}  spacing={1}>
            
            <TextField
            variant="outlined" 
            value={this.state.three_bottom_reward}
            onChange={(e) => { this.setState({ three_bottom_reward:  e.target.value }); }}
            maxlength="50"
            margin="dense"
            label="รางวัลสามตัวล่าง"
            type="number"
            fullWidth
            />
            </Grid>


            <Grid item sm={1} md={1} xs={12}  spacing={1}>
            
            <TextField
            variant="outlined" 
            value={this.state.three_option_reward}
            onChange={(e) => { this.setState({ three_option_reward:  e.target.value }); }}
            maxlength="50"
            margin="dense"
            label="รางวัลสามตัวโต๊ด"
            type="number"
            fullWidth
            />
            </Grid>

            <Grid item sm={1} md={1} xs={12}  spacing={1}>
            
            <TextField
            variant="outlined" 
            value={this.state.one_top_reward}
            onChange={(e) => { this.setState({ one_top_reward:  e.target.value }); }}
            maxlength="50"
            margin="dense"
            label="รางวัลวิ่งบน"
            type="number"
            fullWidth
            />
            </Grid>

            <Grid item sm={1} md={1} xs={12}  spacing={1}>
            
            <TextField
            variant="outlined" 
            value={this.state.one_bottom_reward}
            onChange={(e) => { this.setState({ one_bottom_reward:  e.target.value }); }}
            maxlength="50"
            margin="dense"
            label="รางวัลวิ่งล่าง"
            type="number"
            fullWidth
            />
            </Grid>

            

            <Grid item sm={1} md={1} xs={12}  spacing={1}>
            
            <TextField style={{marginLeft:'40px'}}
              inputProps={{style: {fontSize: 40}}} // font size of input text
              InputLabelProps={{style: {fontSize: 22}}} // font size of input label
            variant="outlined" 
            value={this.state.share_percent}
            onChange={(e) => { this.setState({ share_percent:  e.target.value }); }}
            maxlength="50"
            margin="dense"
            label="ส่วนแบ่งขาย"
            type="number"
            fullWidth
            />
            </Grid>


 </Grid>

{/* ---------------------------------------------------------------- */}
   


            <Grid item sm={3} md={3} xs={12}  spacing={1}>
                <Button
                fullWidth
                onClick={(e)=>{  this.refresh(); }}
                variant="contained"
                color="primary"
                size="medium"
                className={classes.button}
                startIcon={  <Icon >refresh</Icon> }
                >
                คำนวณกำไร
                </Button>
            </Grid>

            <Grid item sm={9} md={9} xs={12}  spacing={3} style={{display: 'flex',alignItems: 'center',justifyContent: 'flex-start',}} >
            <Chip
            avatar={<Avatar>M</Avatar>}
            label={this.state.chip_total}
            variant="outlined"
            color="secondary"
            
            />

          <Chip style={{marginLeft:'20px'}}
            avatar={<Avatar>S</Avatar>}
            label={this.state.chip_share_percent}
            variant="outlined"
            color="secondary"
            
            />

                    <Chip  style={{marginLeft:'20px'}}
            avatar={<Avatar>M</Avatar>}
            label={this.state.chip_total_reward}
            variant="outlined"
            color="secondary"
            
            />

<Chip  style={{marginLeft:'20px'}}
            avatar={<Avatar>P</Avatar>}
            label={this.state.chip_total_profit}
            variant="outlined"
            color="secondary"
             
            />


            </Grid>

        </Grid>
   
                

        <Box mt={3} width={1}   >



          <Grid  spacing={3}    >


           

            <TableContainer component={Paper}  className={classes.tableWrapper} >


              <Table className={classes.table}  size="small" aria-label="a dense table" >
                <TableHead>

                  <TableRow>
                    <TableCell width="50px">#</TableCell>

                    <TableCell width="80px"  >ผู้ส่ง</TableCell>
                    
                    <TableCell >ซื้อ 2บน</TableCell>
                    <TableCell style={{color: 'lime'}}>ถูก 2บน</TableCell>

                    <TableCell >ซื้อ 2ล่าง</TableCell>
                    <TableCell style={{color: 'lime'}}>ถูก 2ล่าง</TableCell>

                    <TableCell >ซื้อ 3บน</TableCell>
                    <TableCell style={{color: 'lime'}}>ถูก 3บน</TableCell>

                    <TableCell >ซื้อ 3ล่าง</TableCell>
                    <TableCell style={{color: 'lime'}}>ถูก 3ล่าง</TableCell>


                    <TableCell >ซื้อ 3โต๊ด</TableCell>
                    <TableCell style={{color: 'lime'}}>ถูก 3โต๊ด</TableCell>

                    <TableCell >ซื้อ วิ่งบน</TableCell>
                    <TableCell style={{color: 'lime'}}>ถูก วิ่งบน</TableCell>
                    <TableCell >ซื้อ วิ่งล่าง</TableCell>
                    <TableCell style={{color: 'lime'}}>ถูก วิ่งล่าง</TableCell>

                   
                   
                    <TableCell >รวมซื้อ</TableCell>
                    <TableCell >รวมถูก</TableCell>

                  </TableRow>
                </TableHead>
                <TableBody>
                  {datas.map((row, index) => (

                    <TableRow hover  style={{cursor:'pointer'}}   onClick={(e)=>{ this.rowSelected(row);}}>
                      <TableCell component="th" scope="row"  >
                        {index+1}
                      </TableCell>

                      <TableCell align="left">{window.company_id_parent!==''?row.sender_id:row.company_id}-{row.sender_name} </TableCell>

                      <TableCell align="right">{this.formatNumber(row.two_top)} </TableCell>
                      <TableCell align="right"  style={{color: 'lime'}}>{this.formatNumber(row.two_top_reward)} </TableCell>
                      <TableCell align="right">{this.formatNumber(row.two_bottom)} </TableCell>
                      <TableCell align="right"  style={{color: 'lime'}}>{this.formatNumber(row.two_bottom_reward)} </TableCell>

                      <TableCell align="right">{this.formatNumber(row.three_direct)} </TableCell>
                      <TableCell align="right"  style={{color: 'lime'}}>{this.formatNumber(row.three_direct_reward)} </TableCell>

                      <TableCell align="right">{this.formatNumber(row.three_bottom)} </TableCell>
                      <TableCell align="right"  style={{color: 'lime'}}>{this.formatNumber(row.three_bottom_reward)} </TableCell>

                      <TableCell align="right">{this.formatNumber(row.three_option)} </TableCell>
                      <TableCell align="right"  style={{color: 'lime'}}>{this.formatNumber(row.three_option_reward)} </TableCell>

                      <TableCell align="right">{this.formatNumber(row.one_top)} </TableCell>
                      <TableCell align="right"  style={{color: 'lime'}}>{this.formatNumber(row.one_top_reward)} </TableCell>
                      <TableCell align="right">{this.formatNumber(row.one_bottom)} </TableCell>
                      <TableCell align="right"  style={{color: 'lime'}}>{this.formatNumber(row.one_bottom_reward)} </TableCell>

 

                      <TableCell align="right">{this.formatNumber(row.data_sum)} </TableCell>
                      <TableCell align="right" style={row.data_sum_reward>0?{color:'#007bff'}:{}}>{this.formatNumber(row.data_sum_reward)} </TableCell>
                    </TableRow>
                  ))} 
                
                 
                <TableRow hover   onClick={(e)=>{ this.rowSelected({sender_id:'',sender_name:''});}} style={{cursor:'pointer'}}>
                       

                      <TableCell component="th" scope="row"    ></TableCell>
                      <TableCell align="left"   >ทั้งหมด</TableCell>

                      <TableCell align="right">{this.formatNumber(two_top)} </TableCell>
                      <TableCell align="right" style={{color: 'lime'}}>{this.formatNumber(two_top_reward)} </TableCell>
                      <TableCell align="right">{this.formatNumber(two_bottom)} </TableCell>
                      <TableCell align="right" style={{color: 'lime'}}>{this.formatNumber(two_bottom_reward)} </TableCell>

                      <TableCell align="right">{this.formatNumber(three_direct)} </TableCell>
                      <TableCell align="right" style={{color: 'lime'}}>{this.formatNumber(three_direct_reward)} </TableCell>

                      <TableCell align="right">{this.formatNumber(three_bottom)} </TableCell>
                      <TableCell align="right" style={{color: 'lime'}}>{this.formatNumber(three_bottom_reward)} </TableCell>

                      <TableCell align="right">{this.formatNumber(three_option)} </TableCell>
                      <TableCell align="right" style={{color: 'lime'}}>{this.formatNumber(three_option_reward)} </TableCell>

                      <TableCell align="right">{this.formatNumber(one_top)} </TableCell>
                      <TableCell align="right" style={{color: 'lime'}}>{this.formatNumber(one_top_reward)} </TableCell>
                      <TableCell align="right">{this.formatNumber(one_bottom)} </TableCell>
                      <TableCell align="right" style={{color: 'lime'}}>{this.formatNumber(one_bottom_reward)} </TableCell>


                    

                      <TableCell align="right">{this.formatNumber(data_sum)} </TableCell>
                      <TableCell align="right" style={data_sum_reward>0?{color:'#007bff'}:{}}>{this.formatNumber(data_sum_reward)} </TableCell>
                    </TableRow>
                

                </TableBody>
              </Table>
            </TableContainer>
           

          </Grid>
        </Box>

       
        <Dialog
          onClose={() => { this.setState({ open_reports: false }); }}
          aria-labelledby="รายงานผลรางวัล/ตามผู้ส่ง"
          open={this.state.open_reports}
          fullScreen
        >

          <AppBar className={classes.appBar}>
            <Toolbar variant="dense"  >
              <IconButton edge="start" color="inherit" onClick={() => { this.setState({ open_reports: false }); }} aria-label="close">
                <Icon color="secondary">close</Icon>
              </IconButton>
              <Typography variant="h6" className={classes.title}>รายงานผลรางวัล/ตามผู้ส่ง</Typography>


            </Toolbar>
          </AppBar>

          <DialogContent     >
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"

            >
              <ResultRewardSender  messages={this.state}  />


            </Grid>
          </DialogContent>


        </Dialog>

        <Snackbar open={this.state.Snackbar} autoHideDuration={4000} onClose={() => { this.setState({ Snackbar: false }); }}>
          <Alert onClose={() => { this.setState({ Snackbar: false }); }} severity="info">{this.state.message}</Alert>
        </Snackbar>

      </div >



    );
  };


}
export default withStyles(styles)(Result)

//export default withStyles(styles, { withTheme: true })(Products);