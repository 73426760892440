import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';

import Icon from '@material-ui/core/Icon';
 
import FormControlLabel from '@material-ui/core/FormControlLabel';
import SwitchControl from '@material-ui/core/Switch';
 
import {
 // BrowserRouter as Router,
  Switch,
  Route,
  Link ,
  useHistory 
} from "react-router-dom";

import SignIn from './components/signin';
import Users from "./components/users";
import Companys from "./components/companys";
import Calendars from "./components/calendars";
import Transtactions from "./components/transactions";
import Risk from "./components/risk";
import Result from "./components/result";


import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

import maincolor from '@material-ui/core/colors/blueGrey';
import secondcolor from '@material-ui/core/colors/yellow';



const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },

 

  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  
}));




export default class App extends React.Component {
  constructor(props, context) {
    super(props, context)
   
 //   console.log('created logout button');
   // window.App=this;
    
  /*  this.state = {
      text: "Initial State"
    }
    updateState = updateState.bind(this)*/

  }

 
  
  render() {

    return (

      <MyApplication />

    );
  }
}

//export default
function MyApplication() {

//export default function App() {

  
 

  const [dark, setDark] = React.useState(true);

  var palettetype = localStorage.getItem('type');
  if (palettetype === null) {
    palettetype = 'dark';
  }

 

  const theme = createMuiTheme({
    palette: {
   type:  palettetype,
       primary:  {main:maincolor[500]},
      secondary:{main:secondcolor[500]},
      // Used by `getContrastText()` to maximize the contrast between
      // the background and the text.
      contrastThreshold: 3,
      // Used by the functions below to shift a color's luminance by approximately
      // two indexes within its tonal palette.
      // E.g., shift from Red 500 to Red 300 or Red 700.
      tonalOffset: 0.2,
    },
  });

  
  
  //var token = localStorage.getItem('token');
  //var user = '';
  
  var SIGNIN = false;
  var menu_database=false;

  var SETTINGS, TRN000, TRN001, TRN002, MST001, MST002, MST003,MST004, menu_daily;



  var menus='';
  if(window.menus){menus=window.menus};
  
  let history = useHistory();


  if(menus==='')
  {
    history.push("/signin");
  }


  if(menus!=='')
  {
    if (menus.indexOf("SETTINGS") !== -1) {
      SETTINGS = true;
    }

    if (menus.indexOf("TRN000") !== -1) {
      TRN000 = true;
    }
    if (menus.indexOf("TRN001") !== -1) {
      TRN001 = true;
    }
    if (menus.indexOf("TRN002") !== -1) {
      TRN002 = true;
    }
    if (TRN001 || TRN002) {
      menu_daily = true;
    }
    //database
    if (menus.indexOf("MST001") !== -1) {
      MST001 = true;
    }
    if (menus.indexOf("MST002") !== -1) {
      MST002 = true;
    }
    if (menus.indexOf("MST003") !== -1) {
      MST003 = true;
    }
    if (menus.indexOf("MST004") !== -1) {
      MST004 = true;
    }
    if (MST001 || MST002 || MST003 || MST004) {
      menu_database = true;
    }
  
    var token = localStorage.getItem('token');
    if (token)
    {
      SIGNIN = true;
    }
  }
  

  var email = '';
 
  if (SIGNIN===true) {
    email = localStorage.getItem('email');
    
  }



  const signout = () => {
    localStorage.removeItem('token');
      window.user_id=null;
      window.company_id=null;


   /* localStorage.removeItem('id');
    localStorage.removeItem('user');
    localStorage.removeItem('menus');*/
    
    history.push("/signin");
  // window.location='/signin';
    
   
  }

  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (

  
 <ThemeProvider theme={theme}>
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            เจ้ามือหวย 2020 
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
         
        

        <div className={classes.drawerHeader}>
        
        <Typography variant="h6" noWrap>
        {email}
          </Typography>

          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div>
        <Divider />

        
 <List className={SIGNIN ? '' : 'hidden'}    onClick={handleDrawerClose}> 

  {SIGNIN && menu_database &&  (
  <ListSubheader  >ข้อมูลหลัก</ListSubheader>
  )}

 {SIGNIN   &&
    (<ListItem button  component={Link}  to="/home" >
      <ListItemIcon><Icon>dashboard</Icon></ListItemIcon>
      <ListItemText primary="Home" />
    </ListItem>

    )}

{SIGNIN && MST001  &&   (
  <ListItem button   component={Link} to="/companys">
    <ListItemIcon><Icon>people_alt</Icon> </ListItemIcon>
    <ListItemText primary="กลุ่มผู้ใช้งาน" />
  </ListItem>
 )}
 
 {SIGNIN && MST002 &&  (
    <ListItem button   component={Link} to="/users">
              <ListItemIcon><Icon>person_add</Icon></ListItemIcon>
              <ListItemText primary="พนักงาน" />
    </ListItem>
    )}
    {SIGNIN && MST003  &&  (
    <ListItem button   component={Link} to="/calendars">
              <ListItemIcon><Icon>today</Icon> </ListItemIcon>
              <ListItemText primary="ตารางปฏิทิน" />
    </ListItem>
    )}
  {/*SIGNIN && MST004 && (
    <ListItem button   component={Link} >
              <ListItemIcon><Icon>reorder</Icon> </ListItemIcon>
              <ListItemText primary="รูปแบบบันทึก" />
    </ListItem>
  )*/}
    </List>
  
    <List className={SIGNIN ? '' : 'hidden'}     onClick={handleDrawerClose}> 
    {SIGNIN && menu_daily && (
    <ListSubheader  >ประจำวัน</ListSubheader>
    )}

    {SIGNIN && TRN000 && (
      <ListItem button   component={Link}  to="/transactions">
              <ListItemIcon  ><Icon>save</Icon> </ListItemIcon>
              <ListItemText primary="บันทึกข้อมูล"  />
    </ListItem>
    )}

{SIGNIN && TRN001 && (
      <ListItem button   component={Link}  to="/risk" >
              <ListItemIcon  ><Icon>trending_up</Icon> </ListItemIcon>
              <ListItemText primary="ประเมินความเสี่ยง"  />
    </ListItem>
    )}
 
{SIGNIN && TRN002 && (
      <ListItem button   component={Link}  to="/result"  >
              <ListItemIcon  ><Icon>poll</Icon> </ListItemIcon>
              <ListItemText primary="คำนวณผลกำไร"  />
    </ListItem>
    )}

  </List>

   
  <List subheader={<ListSubheader>ระบบ</ListSubheader>}   onClick={handleDrawerClose}> 
  {!SIGNIN &&
    ( 
      <ListItem button key="SIGNIN"  onClick={handleDrawerClose} component={Link} to="/signin" >
        <ListItemIcon><Icon>account_circle</Icon></ListItemIcon>
        <ListItemText primary="เข้าสู่ระบบ" />
      </ListItem>
    )}
  {SIGNIN &&
    (<ListItem button key="SIGNOUT" component={Link}   >
      <ListItemIcon><Icon>power_settings_new</Icon></ListItemIcon>
      <ListItemText primary="ออกจากระบบ"  onClick={signout}  />
    </ListItem>

    )}

<Divider />
  <ListItem   >

    <FormControlLabel control={
      <SwitchControl color="primary"
        checked={palettetype === 'dark' ? true : false}
        onChange={(e) => { localStorage.setItem('type', e.target.checked ? 'dark' : 'light'); setDark(!dark); }}
      />
    } label="โหมดกลางคืน" />

  </ListItem>

</List> 

      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.drawerHeader} />
        



        {/*
          A <Switch> looks through all its children <Route>
          elements and renders the first one whose path
          matches the current URL. Use a <Switch> any time
          you have multiple routes, but you want only one
          of them to render at a time
        */}
        <Switch>
          <Route exact path="/signin" >
            <SignIn authorized={ handleDrawerClose }/>
          </Route>
          
           <Route path="/home">
            <Home />
          </Route>
          <Route path="/companys">
            <Companys />
          </Route>
	        <Route path="/users">
              <Users />
          </Route>
          <Route path="/calendars">
              <Calendars />
          </Route>
          <Route path="/transactions">
              <Transtactions />
          </Route>
          <Route path="/risk">
              <Risk />
          </Route>
          <Route path="/result">
              <Result />
          </Route>
        </Switch>
     
   

      </main>
    </div>
</ThemeProvider>
 

  );
}


function Home() {
  return (
    <div>
      <h2>Home</h2>
    </div>
  );
}

