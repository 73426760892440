import React, { Component } from "react";
//, { Component } 
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from "react-router-dom";

import { fade, withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Box from '@material-ui/core/Box';
import API from "../utils/API";


import Icon from '@material-ui/core/Icon';

import TextField from '@material-ui/core/TextField';


import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import Button from '@material-ui/core/Button';

import {store} from '../utils/store';


//calendar picker
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import {
  DatePicker,
//  TimePicker,
// DateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

import Autocomplete from '@material-ui/lab/Autocomplete';
 
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
//import { ThreeSixtyTwoTone } from "@material-ui/icons";


//Dialog
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';

import TicketDetail_Number from "./ticketdetail_number";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  paper: {
    margin: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  table: { },
  tableWrapper: {
    width:'100%',
    overflow: 'auto'
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  imageStyle: {
    width: '100px', height: '75px',
  }
});




class Risk extends Component {
  //export default function Products() {

  constructor(props) {
    super(props);
   // this.PageChanged = this.PageChanged.bind(this);

    if ( window.user_id===undefined || window.user_id===null)
    {
        window.location='/signin';
    }

    let user_id='';
    let company_id='';

    if(window.user_id)
    {
      user_id=window.user_id;
    }
   
    if(window.company_id)
    {
      company_id=window.company_id;
    }

    let two_top_reward=localStorage.getItem('two_top_reward');
    let two_bottom_reward=localStorage.getItem('two_bottom_reward');

    let three_direct_reward=localStorage.getItem('three_direct_reward');
    let three_bottom_reward=localStorage.getItem('three_bottom_reward');
    let three_option_reward=localStorage.getItem('three_option_reward');

    let one_top_reward=localStorage.getItem('one_top_reward');
    let one_bottom_reward=localStorage.getItem('one_bottom_reward');
    
    let two_top_limit=localStorage.getItem('two_top_limit');
    let two_bottom_limit=localStorage.getItem('two_bottom_limit');

    let three_direct_limit=localStorage.getItem('three_direct_limit');
    let three_bottom_limit=localStorage.getItem('three_bottom_limit');
    let three_option_limit=localStorage.getItem('three_option_limitlocalStorage');

    let one_top_limit=localStorage.getItem('one_top_limit');
    let one_bottom_limit=localStorage.getItem('one_bottom_limit');

    let share_percent=localStorage.getItem('share_percent');

    this.state = {
      open_reports:false,
      url_addition : store.url_addition,
      company_id: company_id,
      user_id:user_id,
    
      selected_date : localStorage.getItem('date')===null? new Date():localStorage.getItem('date') ,
      selected_number:'00',
      senders:[],
      selected_sender:null,
      datatypes:[ { name: 'วิ่ง', id: "1" },{ name: 'สองตัว', id: "2" },{ name: 'สามตัว', id: "3" }],
      selected_datatype:null,
      two_top_reward: two_top_reward===null?70:two_top_reward,
      two_bottom_reward: two_bottom_reward===null?70:two_bottom_reward,

      three_direct_reward: three_direct_reward===null?400:three_direct_reward,
      three_bottom_reward: three_bottom_reward===null?100:three_bottom_reward,
      three_option_reward: three_option_reward===null?80:three_option_reward,

      one_top_reward: one_top_reward===null?3:one_top_reward,
      one_bottom_reward: one_bottom_reward===null?4:one_bottom_reward,

   
      two_top_limit: two_top_limit===null?10000:two_top_limit ,
      two_bottom_limit:two_bottom_limit===null?10000:two_bottom_limit,
      three_direct_limit: three_direct_limit===null?5000:three_direct_limit ,
      three_bottom_limit: three_bottom_limit===null?5000:three_bottom_limit ,
      three_option_limit: three_option_limit===null?10000:three_option_limit ,
      one_top_limit:one_top_limit===null?5000:one_top_limit,
      one_bottom_limit:one_bottom_limit===null?5000:one_bottom_limit,
      share_percent: share_percent===null?25:share_percent,

      chip_total:"รวมขายทั้งหมด 0 บาท",
      chip_share_percent:"ส่วนแบ่งขายทั้งหมด 0 บาท",
      chip_diff:"รวมขายหักส่วนแบ่งแล้ว 0 บาท",
      

      title: "ประเมินความเสี่ยง",
      dataset: 'senders',
     
      waitting: false,
      rows: [],
      selectedItems: {},
     
      search: "",
     
      Snackbar: false,
      message: "",
      

    };
    this.fileInput = React.createRef();
    this.forms = React.createRef();
    this.maxdate();
    this.lookupTable();

     
  }

  Snackbar = (message) => {
    this.setState({ Snackbar: true, message: message });
  };



  SearchChanged = (e) => {
    this.setState({ search: e.target.value });


    if(this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.refresh();
    }, 400);



  };

  /*
  PageChanged = (event, value) => {
    this.refresh(value);
  };*/

  maxdate = () => {
    API.get(this.state.url_addition + 'api/maxdate?company_id=' + this.state.company_id )
    .then((res) => {
       
      if (res.data.error) {
        this.Snackbar(res.data.error);
        this.state.locked=true;
        window.locked=true;
      }
      else
      {
         this.setState({ selected_date:res.data.datas});
      }

    }).catch((error) => {
      this.Snackbar(error)
     
    });

}

  lookupTable = () => {
    API.get(this.state.url_addition + 'lookup?data=senders&company_id=' + this.state.company_id + '&company_id_parent=' + this.state.company_id_parent)
      .then((res) => {
        let items = [];
        let last;

        res.data.datas.forEach(element => {
          if(element.sender_id===window.user_id)
          {
            last={ sender_name: element.sender_name, sender_id: element.sender_id };
          }
         
          items.push({ sender_name: element.sender_name, sender_id: element.sender_id });
        });

        if(window.admin_flag==='N')
        {
          this.setState({ senders: items,selected_sender:last });
          this.refresh();
        }
        else
        {
          this.setState({ senders: items });
        }
       
        this.setState({ senders: items });


      }).catch((error) => {
        console.log(error)
      });

  };


 saveSettings=()=>{

  localStorage.setItem('two_top_reward', this.state.two_top_reward);
  localStorage.setItem('two_bottom_reward', this.state.two_bottom_reward);
  localStorage.setItem('three_direct_reward', this.state.three_direct_reward);
  localStorage.setItem('three_bottom_reward', this.state.three_bottom_reward);
  localStorage.setItem('three_option_reward', this.state.three_option_reward);
  localStorage.setItem('one_top_reward', this.state.one_top_reward);
  localStorage.setItem('one_bottom_reward', this.state.one_bottom_reward);

  localStorage.setItem('two_top_limit', this.state.two_top_limit);
  localStorage.setItem('two_bottom_limit', this.state.two_bottom_limit);
  localStorage.setItem('three_direct_limit', this.state.three_direct_limit);
  localStorage.setItem('three_bottom_limit', this.state.three_bottom_limit);
  localStorage.setItem('three_option_limit', this.state.three_option_limit);
  localStorage.setItem('one_top_limit', this.state.one_top_limit);
  localStorage.setItem('one_bottom_limit', this.state.one_bottom_limit);
  localStorage.setItem('share_percent', this.state.share_percent);

 }

  refresh = () => {

   this.saveSettings();
   var company_id='';
   var sender_id='';
   company_id=this.state.company_id;
   if(this.state.selected_sender!==null)
   {
     sender_id=this.state.selected_sender.sender_id.toString();
   }

  /* if(window.company_id_parent!=='')
   {
    company_id=this.state.company_id;
    if(this.state.selected_sender!==null)
    {
      sender_id=this.state.selected_sender.sender_id.toString();
    }
   }
   else
   {
    sender_id=this.state.company_id;
    if(this.state.selected_sender!==null)
    {
      company_id=this.state.selected_sender.sender_id.toString();
    }
   }*/
   
    var param='risk?version=2&company_id=' + company_id;

    param+= '&company_id_parent=' + window.company_id_parent;

    param+="&date=" + this.formatDate(this.state.selected_date.toString()) ;
    var sender='';
  
    param+="&sender=" + sender_id;

    param+="&two_top_reward=" + this.state.two_top_reward;
    param+="&two_bottom_reward=" + this.state.two_bottom_reward;
    param+="&three_direct_reward=" + this.state.three_direct_reward;
    param+="&three_bottom_reward=" + this.state.three_bottom_reward;
    param+="&three_option_reward=" + this.state.three_option_reward;
    param+="&one_top_reward=" + this.state.one_top_reward;
    param+="&one_bottom_reward=" + this.state.one_bottom_reward;
   
    param+="&two_top_limit=" + this.state.two_top_limit;
    param+="&two_bottom_limit=" + this.state.two_bottom_limit;
    param+="&three_direct_limit=" + this.state.three_direct_limit;
    param+="&three_bottom_limit=" + this.state.three_bottom_limit;
    param+="&three_option_limit=" + this.state.three_option_limit
    param+="&one_top_limit=" + this.state.one_top_limit;
    param+="&one_bottom_limit=" + this.state.one_bottom_limit;
    
    param+="&search=" + this.state.search +"";

    API.get(this.state.url_addition   + param )
    .then((res) => {
       
      this.setState({rows: res.data.datas });
    }).catch((error) => {
      console.log(error)
      alert('กรูณาเข้าสู่ระบบอีกครั้ง ' + error);
    });
  }

  formatDate=(date)=>{
    var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

    if (month.length < 2) 
    month = '0' + month;
    if (day.length < 2) 
    day = '0' + day;

    return [year, month, day].join('-');
  } 

   formatNumber = inputNumber => {
    let formetedNumber=(Number(inputNumber)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    let splitArray=formetedNumber.split('.');
    if(splitArray.length>1){
      formetedNumber=splitArray[0];
    }
    return(formetedNumber);
  };


  rowSelected=(item)=>
  {
    if(item!==null)
    {
     // let temp=window.company_id_parent!==''?item.sender_id:item.company_id;
      //row.data_number
      
      this.setState({
        sender_name:this.state.selected_sender===null?"":this.state.selected_sender.sender_name.toString() ,
        sender_id: this.state.selected_sender===null?"":this.state.selected_sender.sender_id.toString() ,
         open_reports: true,
         selected_number: item.data_number 
        });
      //this.Snackbar('You are click :' + item.sender_id);
    }
  }


  componentDidMount() {
    //  alert('componentDidMount');

  }

  render() {
    const { classes } = this.props;
    var datas=[];
 
   // alert(this.state.selected_datatype.id);

    if(this.state.selected_datatype!==null && this.state.selected_datatype!=='' &&   typeof this.state.rows !== 'undefined' )
    {
     
     datas=this.state.rows.filter(item => item.data_number.length===parseInt(this.state.selected_datatype.id) )
    
    }
    else
    {
      datas=this.state.rows;
    }
    
   // console.log(datas);
   
    var total=  datas.reduce((a,v) =>  a = a + parseInt(v.data_value) , 0 ) ;
    var share_percent =total*(this.state.share_percent/100.00);
    var diff=total-share_percent;

    this.state.chip_total="รวมขายทั้งหมด " + this.formatNumber(total) + " บาท";
    this.state.chip_share_percent="ส่วนแบ่งขายทั้งหมด " + this.formatNumber(share_percent) + " บาท";
    this.state.chip_diff="รวมขายหักส่วนแบ่งแล้ว " + this.formatNumber(diff) + " บาท";
    this.diff=diff;

    return (
      <div className={classes.root} >
        <Box m={0}    >
          <Grid container spacing={1}    >

            <Grid item spacing={1} sm={9} xs={12}  >
              <Breadcrumbs aria-label="breadcrumb">
                <Link color="inherit" to="/home" style={{ color: 'inherit', textDecoration: 'inherit'}} > Home  </Link>
                <Typography color="textPrimary">{this.state.title}</Typography>
              </Breadcrumbs>
            </Grid>
            <Grid item spacing={1} sm={3}   xs={12} justify="flex-end" >

             
             
                <Grid item>
                  <TextField label="ค้นหา" value={this.state.search}
                    onChange={this.SearchChanged} variant="outlined"  fullWidth dense />
                   
                </Grid>
              
               

            </Grid>
            <Grid item spacing={1}   >

 
             
             
            </Grid>


          </Grid>
        </Box>

        <Grid container spacing={1}   className={!this.state.editable?classes.hide:''} >


            <Grid item sm={6} md={6} xs={12}  spacing={1}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker 
            fullWidth
            disabled={window.admin_flag==='Y'?false:true}
            inputVariant="outlined"
            format="dd/MM/yyyy"
            value={this.state.selected_date} 
            onChange={ (date)=>{  localStorage.setItem('date',date); this.setState({selected_date:date});     }    } 
            label="วันทึ่"
            />
            </MuiPickersUtilsProvider>
            </Grid>


            <Grid item sm={3} md={3} xs={12}  spacing={1}>
            <Autocomplete
            id="cbo_sender"
            options={this.state.senders}
            autoFocus
            disabled={window.admin_flag==='Y'?false:true}
            //  getOptionLabel={(option) => option.sender_name}
            getOptionLabel={(option) => { return option.sender_name; }}
            renderInput={(params) => <TextField {...params} label="ผู้ส่ง" variant="outlined" />}
            onChange={(event, value) =>{   this.setState({selected_sender:value}); }   }
            value={this.state.selected_sender}
            />
            </Grid>

            <Grid item sm={3} md={3} xs={12}  spacing={1}>
            <Autocomplete
            id="cbo_datatype"
            options={this.state.datatypes}
            autoFocus
            disabled={window.admin_flag==='Y'?false:true}
            //  getOptionLabel={(option) => option.datatype_name}
            getOptionLabel={(option) => { return option.name; }}
            renderInput={(params) => <TextField {...params} label="ชนิดข้อมูล" variant="outlined" />}
            onChange={(event, value) =>{   this.setState({selected_datatype:value})   }   }
            value={this.state.selected_datatype}
            />
            </Grid>


<Grid item sm={12} md={12} xs={12}  spacing={1} container >   

            <Grid item sm={1} md={1} xs={12}  spacing={1}>
            
                    <TextField
                    variant="outlined" 
                    value={this.state.two_top_reward}
                    onChange={(e) => { this.setState({ two_top_reward:  e.target.value }); }}
                    maxlength="50"
                    margin="dense"
                    label="รางวัลสองตัวบน"
                    type="number"
                    fullWidth
                    />
            </Grid>
            <Grid item sm={1} md={1} xs={12}  spacing={1}>
            
                    <TextField
                    variant="outlined" 
                    value={this.state.two_bottom_reward}
                    onChange={(e) => { this.setState({ two_bottom_reward:  e.target.value }); }}
                    maxlength="50"
                    margin="dense"
                    label="รางวัลสองตัวล่าง"
                    type="number"
                    fullWidth
                    />
            </Grid>

            <Grid item sm={1} md={1} xs={12}  spacing={1}>
            
            <TextField
            variant="outlined" 
            value={this.state.three_direct_reward}
            onChange={(e) => { this.setState({ three_direct_reward:  e.target.value }); }}
            maxlength="50"
            margin="dense"
            label="รางวัลสามตัวบน"
            type="number"
            fullWidth
            />
            </Grid>

            <Grid item sm={1} md={1} xs={12}  spacing={1}>
            
            <TextField
            variant="outlined" 
            value={this.state.three_bottom_reward}
            onChange={(e) => { this.setState({ three_bottom_reward:  e.target.value }); }}
            maxlength="50"
            margin="dense"
            label="รางวัลสามตัวล่าง"
            type="number"
            fullWidth
            />
            </Grid>

            <Grid item sm={1} md={1} xs={12}  spacing={1}>
            
            <TextField
            variant="outlined" 
            value={this.state.three_option_reward}
            onChange={(e) => { this.setState({ three_option_reward:  e.target.value }); }}
            maxlength="50"
            margin="dense"
            label="รางวัลสามตัวโต๊ด"
            type="number"
            fullWidth
            />
            </Grid>

            <Grid item sm={1} md={1} xs={12}  spacing={1}>
            
            <TextField
            variant="outlined" 
            value={this.state.one_top_reward}
            onChange={(e) => { this.setState({ one_top_reward:  e.target.value }); }}
            maxlength="50"
            margin="dense"
            label="รางวัลวิ่งบน"
            type="number"
            fullWidth
            />
            </Grid>

            <Grid item sm={1} md={1} xs={12}  spacing={1}>
            
            <TextField
            variant="outlined" 
            value={this.state.one_bottom_reward}
            onChange={(e) => { this.setState({ one_bottom_reward:  e.target.value }); }}
            maxlength="50"
            margin="dense"
            label="รางวัลวิ่งล่าง"
            type="number"
            fullWidth
            />
            </Grid>

 </Grid>
{/* ---------------------------------------------------------------- */}
<Grid item sm={12} md={12} xs={12}  spacing={1} container>   
            <Grid item sm={1} md={1} xs={12}  spacing={1}>
            
            <TextField
            variant="outlined" 
            value={this.state.two_top_limit}
            onChange={(e) => { this.setState({ two_top_limit:  e.target.value }); }}
            maxlength="50"
            margin="dense"
            label="จำกัดสองตัวบน"
            type="number"
            fullWidth
            />
    </Grid>
    <Grid item sm={1} md={1} xs={12}  spacing={1}>
    
            <TextField
            variant="outlined" 
            value={this.state.two_bottom_limit}
            onChange={(e) => { this.setState({ two_bottom_limit:  e.target.value }); }}
            maxlength="50"
            margin="dense"
            label="จำกัดสองตัวล่าง"
            type="number"
            fullWidth
            />
    </Grid>

    <Grid item sm={1} md={1} xs={12}  spacing={1}>
    
    <TextField
    variant="outlined" 
    value={this.state.three_direct_limit}
    onChange={(e) => { this.setState({ three_direct_limit:  e.target.value }); }}
    maxlength="50"
    margin="dense"
    label="จำกัดสามตัวบน"
    type="number"
    fullWidth
    />
    </Grid>

    <Grid item sm={1} md={1} xs={12}  spacing={1}>
    
    <TextField
    variant="outlined" 
    value={this.state.three_bottom_limit}
    onChange={(e) => { this.setState({ three_bottom_limit:  e.target.value }); }}
    maxlength="50"
    margin="dense"
    label="จำกัดสามตัวล่าง"
    type="number"
    fullWidth
    />
    </Grid>

    <Grid item sm={1} md={1} xs={12}  spacing={1}>
    
    <TextField
    variant="outlined" 
    value={this.state.three_option_limit}
    onChange={(e) => { this.setState({ three_option_limit:  e.target.value }); }}
    maxlength="50"
    margin="dense"
    label="จำกัดสามตัวโต๊ด"
    type="number"
    fullWidth
    />
    </Grid>

    <Grid item sm={1} md={1} xs={12}  spacing={1}>
    
    <TextField
    variant="outlined" 
    value={this.state.one_top_limit}
    onChange={(e) => { this.setState({ one_top_limit:  e.target.value }); }}
    maxlength="50"
    margin="dense"
    label="จำกัดวิ่งบน"
    type="number"
    fullWidth
    />
    </Grid>

    <Grid item sm={1} md={1} xs={12}  spacing={1}>
    
    <TextField
    variant="outlined" 
    value={this.state.one_bottom_limit}
    onChange={(e) => { this.setState({ one_bottom_limit:  e.target.value }); }}
    maxlength="50"
    margin="dense"
    label="จำกัดวิ่งล่าง"
    type="number"
    fullWidth
    />
    </Grid>

    <Grid item sm={1} md={1} xs={12}  spacing={1}>
            
            <TextField style={{marginLeft:'40px'}}
              inputProps={{style: {fontSize: 40}}} // font size of input text
              InputLabelProps={{style: {fontSize: 22}}} // font size of input label
            variant="outlined" 
            value={this.state.share_percent}
            onChange={(e) => { this.setState({ share_percent:  e.target.value }); }}
            maxlength="50"
            margin="dense"
            label="ส่วนแบ่งขาย"
            type="number"
            fullWidth
            />
            </Grid>


</Grid>


            <Grid item sm={3} md={3} xs={12}  spacing={1}>
                <Button
                fullWidth
                onClick={(e)=>{  this.refresh(); }}
                variant="contained"
                color="primary"
                size="medium"
                className={classes.button}
                startIcon={  <Icon >refresh</Icon> }
                >
                ดูข้อมูล
                </Button>
            </Grid>

            <Grid item sm={9} md={9} xs={12}  spacing={1} style={{display: 'flex',alignItems: 'center',justifyContent: 'flex-start',}} >
            <Chip style={{marginLeft:'20px'}}
            avatar={<Avatar>M</Avatar>}
            label={this.state.chip_total}
            variant="outlined"
            color="secondary"
            
            />

          <Chip  style={{marginLeft:'20px'}}
            avatar={<Avatar>P</Avatar>}
            label={this.state.chip_share_percent}
            variant="outlined"
            color="secondary"
            
            />

          <Chip  style={{marginLeft:'20px'}}
            avatar={<Avatar>P</Avatar>}
            label={this.state.chip_diff}
            variant="outlined"
            color="secondary"
            
            />

            </Grid>

            
        </Grid>
   
                

        <Box mt={3} width={1}   >



          <Grid  spacing={3}  className={classes.tableWrapper}   >


           

            <TableContainer component={Paper} >


              <Table className={classes.table}  size="small" aria-label="a dense table" >
                <TableHead>

                  <TableRow>
                    <TableCell >#</TableCell>
                    <TableCell  >เลข</TableCell>
                    <TableCell >ประเภท</TableCell>

                    <TableCell  >รวมเงิน</TableCell>
                    <TableCell   style={{color: '#6495ED'}}>จำกัดยอด </TableCell>
                    <TableCell   style={{color: 'lime'}}>กรณีถูก </TableCell>

                   
                    <TableCell >ผลต่าง</TableCell>

                  </TableRow>
                </TableHead>
                <TableBody>
                  {datas.map((row, index) => (

                    <TableRow key={row.id}  hover  style={{cursor:'pointer'}}  onClick={(e)=>{ this.rowSelected(row);}} >
                      <TableCell component="th" scope="row"  >
                        {index+1}
                      </TableCell>
                      <TableCell align="left">{row.data_number} </TableCell>
                      <TableCell align="left">{row.data_type} </TableCell>

                      <TableCell align="left">{this.formatNumber(row.data_value)} </TableCell>
                      <TableCell align="left" style={parseInt(row.data_limit)<parseInt(row.data_value)?{color:'orange'}:{color:''}} >{parseInt(row.data_limit)<parseInt(row.data_value)?this.formatNumber(parseInt(row.data_limit)-parseInt(row.data_value)):0} </TableCell>
                    
                      <TableCell align="left">{this.formatNumber(row.data_reward)} </TableCell>
                      <TableCell align="left"> {this.formatNumber(this.diff-row.data_reward)} </TableCell>

                    </TableRow>
                  ))} 
                
                </TableBody>
              </Table>
            </TableContainer>
           

          </Grid>
        </Box>

        <Dialog
          onClose={() => { this.setState({ open_reports: false }); }}
          aria-labelledby="รายงานรายละเอียด/ตามผู้ส่ง/เลขที่ซื้อ"
          open={this.state.open_reports}
          fullScreen
        >

          <AppBar className={classes.appBar}>
            <Toolbar variant="dense"  >
              <IconButton edge="start" color="inherit" onClick={() => { this.setState({ open_reports: false }); }} aria-label="close">
                <Icon color="secondary">close</Icon>
              </IconButton>
              <Typography variant="h6" className={classes.title}>รายงานรายละเอียด/ตามผู้ส่ง/เลขที่ซื้อ  {this.state.selected_number}</Typography>


            </Toolbar>
          </AppBar>

          <DialogContent     >
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"

            >
              
              <TicketDetail_Number  messages={this.state}  />


            </Grid>
          </DialogContent>


        </Dialog>

        <Snackbar open={this.state.Snackbar} autoHideDuration={4000} onClose={() => { this.setState({ Snackbar: false }); }}>
          <Alert onClose={() => { this.setState({ Snackbar: false }); }} severity="info">{this.state.message}</Alert>
        </Snackbar>

      </div >



    );
  };


}
export default withStyles(styles)(Risk)

//export default withStyles(styles, { withTheme: true })(Products);