import React, { Component } from 'react';
import Container from '@material-ui/core/Container';
//import TextField from '@material-ui/core/TextField';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
//import Box from '@material-ui/core/Box';

import Icon from '@material-ui/core/Icon';
import Fab from '@material-ui/core/Fab';

import API from "../utils/API";
import {store} from '../utils/store';


import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = theme => ({
    paper: {
      marginTop: theme.spacing(8),
  
    },
 
    scrollable:{
       overflow:'auto',
       width:'100%',
  },
 
   fab_print : {
     margin: '0px',
     top: '60px',
     left: 'auto',
     bottom: 'auto',
     right: '40px',
     position: 'fixed',
   },
  
  })

  class TicketDetail_Number extends Component {
    //const classes = useStyles();
  
    constructor(props) {
      super(props) //since we are extending class Table so we have to use super in order to override Component class constructor
    
      if ( window.user_id===undefined || window.user_id===null)
      {
          window.location='/signin';
      }
  
      let user_id='';
      let company_id='';
  
      if(window.user_id)
      {
        user_id=window.user_id;
      }
     
      if(window.company_id)
      {
        company_id=window.company_id;
      }

      this.state = { //state is by default an object
                  url_addition : store.url_addition,
                  company_id: company_id,
                  user_id:user_id,
                  selected_date : localStorage.getItem('date')===null? new Date():localStorage.getItem('date') ,
                  rows:[],

      }
      this.parent=this.props.messages;
      this.refresh();
    
   }
 
  
   refresh = () => {
    
    var sender='';
    var company_id='';

    company_id=this.parent.company_id;
    sender=this.parent.sender_id;

   /* if(window.company_id_parent==='')
    {
       //is received
      sender=window.company_id_parent;
      
      if(this.parent.sender_id!==null && this.parent.sender_id!=='')
      {
        company_id=this.parent.sender_id;
      }

    }
    else{
       //sender
      if(this.parent.sender_id!==null && this.parent.sender_id!=='')
      {
        sender=this.parent.sender_id;
      }

      company_id=window.company_id_parent;
    }*/

    var param='risk/detailnumber?company_id=' + company_id;
    param+="&date=" + this.formatDate(this.parent.selected_date.toString()) ;
    param+="&sender=" + sender;
    param+="&number=" + this.parent.selected_number.toString();
  

    API.get(this.state.url_addition   + param )
    .then((res) => {
     //  console.log(res.data);
      this.setState({rows: res.data.datas });
    }).catch((error) => {
      console.log(error)
      alert('กรูณาเข้าสู่ระบบอีกครั้ง ' + error);
    });
  }
  
  formatDate=(date)=>{
    var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

    if (month.length < 2) 
    month = '0' + month;
    if (day.length < 2) 
    day = '0' + day;

    return [year, month, day].join('-');
  } 

   printDiv(divName) {
  
    var printContents = document.getElementById(divName).innerHTML;
  
     var winPrint = window.open( 'left=0,top=0,width=800,height=600,toolbar=0,scrollbars=0,status=0', "_blank");
     winPrint.document.write(printContents);
    // winPrint.document.close();
    winPrint.document.title='รายงานสรุป';
     winPrint.focus();
     winPrint.print();
    // winPrint.close(); 
  
  }
   
  
   render() { 
   
    //console.log(this.parent.rows);
      const { classes, theme } = this.props
    
    
      return (
        <Container component="main"  >
         
         <div className={classes.scrollable} id="print_area"    >
  
              <h1 id='title' >
                 ผู้ส่ง : {this.parent.sender_name===''?'ทั้งหมด':this.parent.sender_name} 
              
              </h1>
  
               
              <Grid  spacing={3}  className={classes.tableWrapper}   >


           

<TableContainer component={Paper} >


  <Table className={classes.table}  size="small" aria-label="a dense table" >
    <TableHead>

      <TableRow>
        <TableCell width="50px">#</TableCell>
        <TableCell >ผู้ส่ง</TableCell>
        <TableCell >กลุ่ม</TableCell>
        <TableCell >ชุด</TableCell>

        <TableCell >ซื้อ บน</TableCell>
        
        <TableCell >ซื้อ ล่าง</TableCell>
        
        <TableCell >ซื้อ โต๊ด</TableCell>
       

      
      </TableRow>
    </TableHead>
    <TableBody>
      {this.state.rows.map((row, index) => (

        <TableRow hover   >
          <TableCell component="th" scope="row"  >
            {index+1}
          </TableCell>
          <TableCell align="left">{row.sender_id}  </TableCell>
          <TableCell align="left">{row.group_id} </TableCell>
          <TableCell align="left">{row.ticket_id} </TableCell>
          <TableCell align="left">{row.data_number} </TableCell>
          <TableCell align="left">{row.data_value1} </TableCell>
          <TableCell align="left">{row.data_value2} </TableCell>
          <TableCell align="left">{row.data_value3} </TableCell>

          

        
        </TableRow>
      ))} 
    
    </TableBody>
  </Table>
</TableContainer>

  </Grid>
              
           </div>
           
  <Fab   aria-label="print" className={classes.fab_print} onClick={ (e)=>{ this.printDiv('print_area'); }  }  >
  <Icon color="primary">print</Icon>
  </Fab>
  
 
  
  </Container>
      )
  
   }
  
  }
    
  export default withStyles(useStyles, { withTheme: true })(TicketDetail_Number)