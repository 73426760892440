import React, { Component } from 'react';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
//import Box from '@material-ui/core/Box';

import Icon from '@material-ui/core/Icon';
import Fab from '@material-ui/core/Fab';

//Dialog
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

 
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import SwitchControl from '@material-ui/core/Switch';

import './ticketdetail.css'; 
import { NavigateBeforeSharp } from '@material-ui/icons';

const useStyles = theme => ({
  paper: {
    marginTop: theme.spacing(8),

  },
  div_number:{
    display: 'inline-block',
    Width:'400px',
    verticalAlign: 'top',
    overflow: 'auto',
    margin:'5px',
    //border: '1px solid #ddd;', //rgba(0, 120,205, 1)
  },
  scrollable:{
     overflow:'auto',
     width:'100%',
},
fab_config : {
   margin: '0px',
   top: '60px',
   left: 'auto',
   bottom: 'auto',
   right: '40px',
   position: 'fixed',
 },
 fab_print : {
   margin: '0px',
   top: '60px',
   left: 'auto',
   bottom: 'auto',
   right: '120px',
   position: 'fixed',
 },

})



  class TicketDetail extends Component {
  //const classes = useStyles();

  constructor(props) {
    super(props) //since we are extending class Table so we have to use super in order to override Component class constructor
   
    let v_format_two='0,33,67,99';
    let v_format_three='0,99,199,299,399,499,599,699,799,899,999';
    let v_format_one='0,9';

    if(localStorage.getItem('format_two')!==null )
    {
      v_format_two=localStorage.getItem('format_two');
    }
    if(localStorage.getItem('format_three')!==null )
    {
      v_format_three=localStorage.getItem('format_three');
    }
    if(localStorage.getItem('format_one')!==null )
    {
      v_format_one=localStorage.getItem('format_one');
    }

    this.state = { //state is by default an object
      format_two:v_format_two,
      format_three:v_format_three,
      format_one:v_format_one,

      two_sort:localStorage.getItem('two_sort')!==null?localStorage.getItem('two_sort'):0,
      three_sort:localStorage.getItem('three_sort')!==null?localStorage.getItem('three_sort'):0,
      one_sort:localStorage.getItem('one_sort')!==null?localStorage.getItem('one_sort'):0,

      format_two_input:v_format_two,
      format_three_input:v_format_three,
      format_one_input:v_format_one,

      format_twoValidator: "",
      format_threeValidator: "",
      format_oneValidator: "",



    }
    this.parent=this.props.messages;

  
 }
/*
 renderTableHeader() {
  let header = Object.keys(this.parent.rows[0])
  return header.map((key, index) => {
     return <th key={index}>{key.toUpperCase()}</th>
  })
}
*/

////////////////////////
existsTable(datas,start,end)
{
   if(start!==-1)
   {
      datas=datas.filter((e) => e.number >= start);
   }
   if(end!==-1)
   {
      datas=datas.filter((e) => e.number <= end);
   }

   if(  datas.length <= 0  )
   {
      return false;
   } 
   else{
      return true;
   }
}

renderHead(datas,start,end,is_three) {

   if(start!==-1)
   {
      datas=datas.filter((e) => e.number >= start);
   }
   if(end!==-1)
   {
      datas=datas.filter((e) => e.number <= end);
   }


  let sum_top = (eval(datas.reduce((a,v) =>  a =  v.top===''? a +'+0':a   +'+' + v.top , 0 )))
  let sum_bottom =  (eval(datas.reduce((a,v) =>  a =   v.bottom===''? a +'+0':a  + '+' + v.bottom  , 0 )))
  let sum_option = 0;
   if(is_three)
   {
      sum_option=  ( eval(datas.reduce((a,v) =>  a =   v.option===''? a +'+0':a  + '+' + v.option  , 0 )) );
   }
      
   /*if(  datas.length <= 0  )
   {
      return null;
   } */

    return (
      <>
      
      <thead>
      <tr style={{background:'rgba(0, 0,0, 0.2)'}} >
      <td colspan={!is_three?4:5}><strong> เลข {start}-{end} : {sum_top + sum_bottom + sum_option} บาท</strong></td>
       </tr> 
       <tr >
       <td width="40px">#</td>
       <td  Width="40px">เลข</td>
       <td  Width="150px">{!is_three?'สองตัวบน':'สามตัวบน'} : {sum_top} บาท</td>
       <td  Width="150px">{!is_three?'สองตัวล่าง':'สามตัวล่าง'} : {sum_bottom} บาท</td>
       { is_three===true ?
       <td  Width="150px">{!is_three?'สองตัวโต็ด':'สามตัวโต็ด'} : {sum_option} บาท</td>
         :null
      }
       </tr>
       </thead>
     
      </>
      
    )
}

 printDiv(divName) {

  var printContents = document.getElementById(divName).innerHTML;

   var winPrint = window.open( 'left=0,top=0,width=800,height=600,toolbar=0,scrollbars=0,status=0', "_blank");
   winPrint.document.write(printContents);
  // winPrint.document.close();
  winPrint.document.title='รายงานสรุป';
   winPrint.focus();
   winPrint.print();
  // winPrint.close(); 

}

 
renderNumber(datas,start=-1,end=-1) {
 
   if(start!==-1)
   {
      datas=datas.filter((e) => e.number >= start);
   }
   if(end!==-1)
   {
      datas=datas.filter((e) => e.number <= end);
   }

   /*if(  datas.length <= 0  )
   {
   return null;
   }*/

   return   datas.map((row, index) => {
      const { number,top,bottom,option } = row //destructuring
      return (
         <tr key={index}>
            <td width="40px" >{index+1}</td>
            <td  Width="40px">{number}</td>
            <td  Width="150px" title={top}>{top}</td>
            <td  Width="150px" title={bottom}>{bottom}</td>
            { number.length===3 ?
             <td  Width="150px" title={option}>{option}</td>
            :  null
            }

         </tr>
      )
   })
 }
 

 set_variables(arr_two,temp_number,var_two) {
   //let arr_two=this.state.format_two.split(',');
  // let temp_number=-1;
  // let var_two=[];

   for(var i=0;i<=arr_two.length-1;i++)
   {
     if(parseInt(arr_two[i])!==0)
     {
        if(temp_number!==parseInt(arr_two[i]))
        {
           var_two.push({'start':temp_number+1,'end' :arr_two[i]});
        }
        temp_number=parseInt(arr_two[i]);

     }
   }
 }


 checkFormat(numbertype='สอ่งตัว',arr,limit=99){
   let err='';
   let temp_number=-1;

   if(parseInt(arr[0])!==0)
   {
      err= err+', ' + numbertype + ' ตัวแรกมีค่าเป็น 0 เ่ท่านั้น.';
   }

  // if (isNaN(parseInt(arr[0]))) {  err=err+', เลขสอง ตัวแรก ระบุตัวเลขเท่านั้น และแบ่งข้อมูลด้วย comma.'; }

   for(var i=0;i<=arr.length-1;i++)
   {
      if (isNaN(parseInt(arr[i]))) {  err=err+', เลขสอง ระบุตัวเลขเท่านั้น และแบ่งข้อมูลด้วย comma.';break; }

    if(parseInt(arr[i])!==0)
     {
       
        if(temp_number>=parseInt(arr[i]))
        {
            err=err+', ' + numbertype + ' การเรียงเลขไม่ถูกต้อง.';
            break;
           //var_two.push({'start':temp_number+1,'end' :arr_two[i]});
        }
        temp_number=parseInt(arr[i]);

     }
   }

   if(parseInt(arr[arr.length-1])!==limit)
   {
      err=err+', ' + numbertype + ' ตัวท้าย มีค่าเป็น ' + limit + ' เ่ท่านั้น.';
   }

  // if (isNaN( parseInt(arr[arr.length-1]) )) {  err=err+', เลขสอง ตัวท้าย ระบุตัวเลขเท่านั้น และแบ่งข้อมูลด้วย comma.'; }

   return err;
 }

 saveConfig()
 {

   let err='';

   //validate two
   let arr_two=this.state.format_two_input.split(',');
   let arr_three=this.state.format_three_input.split(',');
   let arr_one=this.state.format_one_input.split(',');

   err=err+this.checkFormat("สองตัว",arr_two,99);
   err=err+this.checkFormat("สามตัว",arr_three,999);
   err=err+this.checkFormat("วิ่ง",arr_one,9);

  

   if(err==='')
   {
      localStorage.setItem('format_two',this.state.format_two_input)
      localStorage.setItem('format_three',this.state.format_three_input)
      localStorage.setItem('format_one',this.state.format_one_input)

      localStorage.setItem('two_sort',this.state.two_sort)
      localStorage.setItem('three_sort',this.state.three_sort)
      localStorage.setItem('one_sort',this.state.one_sort)

      this.setState(
         {
            format_two:this.state.format_two_input,
            format_three:this.state.format_three_input,
            format_one:this.state.format_one_input,
            open_reports: false ,
         }
      );
      alert('บันทึกข้อมูลแล้ว');

   }
   else
   {
      alert('ข้อมูลไม่ถูกต้อง! ' +  err);
   }
  
 }

 render() { 
 
  //console.log(this.parent.rows);
    const { classes, theme } = this.props
  
   let datas_two=this.parent.rows.filter((e) => e.number !== '' && e.number.length===2) ;
   //sort order asc
   if(this.state.two_sort==='1' )
   {
    datas_two=datas_two.sort((a, b) => a.number > b.number ? 1 : -1);
   }
   

   let sum_top_two = (eval(datas_two.reduce((a,v) =>  a =  v.top===''? a +'+0':a   +'+' + v.top , 0 )))
   let sum_bottom_two =  (eval(datas_two.reduce((a,v) =>  a =   v.bottom===''? a +'+0':a  + '+' + v.bottom  , 0 )))

   let datas_three=this.parent.rows.filter((e) => e.number !== '' && e.number.length===3);
   if(this.state.three_sort==='1' )
   {
    datas_three=datas_three.sort((a, b) => a.number > b.number ? 1 : -1);
   }
   
   let temp=[];
   for (var i = 0; i <= datas_three.length - 1; i++) {
   //  fullfill jason for filter and summary
   let tempOption='';
   if  ( "option" in datas_three[i] ) 
   {
      tempOption= datas_three[i].option;
   }
   temp.push( { number: datas_three[i].number, top: datas_three[i].top, bottom: datas_three[i].bottom, option:tempOption } );
   }

   datas_three=temp;

   let sum_top_three = (eval(datas_three.reduce((a,v) =>  a =  v.top===''? a +'+0':a   +'+' + v.top , 0 )))
   let sum_bottom_three =  (eval(datas_three.reduce((a,v) =>  a =   v.bottom===''? a +'+0':a  + '+' + v.bottom  , 0 )))
   let sum_option_three =   (eval(datas_three.reduce((a,v) =>  a =   v.option===''? a +'+0':a  + '+' + v.option  , 0 )))


   let datas_one=this.parent.rows.filter((e) => e.number !== '' && e.number.length===1);
   if(this.state.one_sort==='1' )
   {
    datas_one=datas_one.sort((a, b) => a.number > b.number ? 1 : -1);
   }

   let sum_top_one = (eval(datas_one.reduce((a,v) =>  a =  v.top===''? a +'+0':a   +'+' + v.top , 0 )))
   let sum_bottom_one =  (eval(datas_one.reduce((a,v) =>  a =   v.bottom===''? a +'+0':a  + '+' + v.bottom  , 0 )))
   

  
    let datas=this.parent.rows;
    let sum_top = (eval(datas.reduce((a,v) =>  a =  v.top===''? a +'+0':a   +'+' + v.top , 0 )))
    let sum_bottom =  (eval(datas.reduce((a,v) =>  a =   v.bottom===''? a +'+0':a  + '+' + v.bottom  , 0 )))

    let arr_two=this.state.format_two.split(',');
    let temp_number=-1;
    let var_two=[];
    temp_number=-1;
    this.set_variables(arr_two,temp_number,var_two);

    let arr_three=this.state.format_three.split(',');
    let var_three=[];
    temp_number=-1;
    this.set_variables(arr_three,temp_number,var_three);

    let arr_one=this.state.format_one.split(',');
    let var_one=[];
    temp_number=-1;
    this.set_variables(arr_one,temp_number,var_one);

    return (
      <Container component="main"  >
       
       <div className={classes.scrollable} id="print_area"    >

            <h1 id='title' >
               ผู้ส่ง : {this.parent.selected_sender.sender_name} 
               &nbsp;&nbsp;&nbsp;กลุ่ม : {this.parent.selected_group.text}
               &nbsp;&nbsp;&nbsp;ชุด : {this.parent.selected_ticket.text}
            </h1>

            <h1 id='title' >ทั้งหมด {sum_top+sum_bottom} บาท</h1>

            <h1 id='title' >สองตัว {sum_top_two}+{sum_bottom_two}={sum_top_two+sum_bottom_two}</h1>
            
            {var_two.map((row, index) => (

            (this.existsTable(datas_two,row.start,row.end) > 0 && 
            <div className={classes.div_number}  >
            <table id='datas'>
            {this.renderHead(datas_two,row.start,row.end,false)}
            <tbody>
            {this.renderNumber(datas_two,row.start,row.end)}
            </tbody>
            </table></div>)

            ))}
            
            
            <h1 id='title' >สามตัว {sum_top_three}+{sum_bottom_three}+{sum_option_three}={sum_top_three+sum_bottom_three+sum_option_three}</h1>
            
            {
              
            var_three.map((row, index) => (

               
            
               (this.existsTable(datas_three,row.start,row.end) > 0 && 
               <div className={classes.div_number}  >
            <table id='datas'>
            {this.renderHead(datas_three,row.start,row.end,true)}
            <tbody>
            {this.renderNumber(datas_three,row.start,row.end)}
            </tbody>
            </table></div>)
             

            ))
           
            }
             

            <h1 id='title' >วิ่ง {sum_top_one}+{sum_bottom_one}={sum_top_one+sum_bottom_one}</h1>
            {var_one.map((row, index) => (

            (this.existsTable(datas_one,row.start,row.end) > 0 && 
            <div className={classes.div_number}  >
            <table id='datas'>
            {this.renderHead(datas_one,row.start,row.end,false)}
            <tbody>
            {this.renderNumber(datas_one,row.start,row.end)}
            </tbody>
            </table></div>)

            ))}
            

            
         </div>
         
<Fab   aria-label="print" className={classes.fab_print} onClick={ (e)=>{ this.printDiv('print_area'); }  }  >
<Icon color="primary">print</Icon>
</Fab>


<Fab   aria-label="config" className={classes.fab_config} onClick={ (e)=>{ this.setState({ open_reports: true }); }  }  >
<Icon color="primary">settings</Icon>
</Fab>

<Dialog 
      onClose={ () => { this.setState({ open_reports: false }); } } 
      aria-labelledby="รูปแบบรายงาน" 
      open={ this.state.open_reports }
      
      >

   
      <DialogTitle id="alert-dialog-slide-title">{"รูปแบบรายงาน?"}</DialogTitle>


      <DialogContent     >
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        
        >
       
       <DialogContentText id="alert-dialog-slide-description">
            ท่านสามารถระบุ 
            รูปแบบ สองตัว ค่า 0-99 สามตัว 0-999 วิ่ง 0-9.
            ตัวอย่างข้อมูล 0,33,67,99 หรือ 0-9 เป็นต้น.
          </DialogContentText>

       
               <Grid item xs={12} spacing={1}>
               <TextField
               required
               autoFocus
                value={this.state.format_two_input}
               onChange={(e) => { this.setState({format_two_input:e.target.value}); }}
               margin="dense"
               label="รูปแบบสองตัว"
               type="text"
               fullWidth
               // error={this.state.sender_idValidator}
               //helperText={this.state.sender_idValidator}
               />
               </Grid>
               <Grid item xs={12} spacing={1}>
                  <FormControlLabel control={
                  <SwitchControl color="primary"
                  checked={this.state.two_sort === '1' ? true : false}
                  onChange={(e) => { this.setState({two_sort:  e.target.checked ? '1' : '0' }) ; localStorage.setItem('two_sort', e.target.checked ? '1' : '0');   }}
                  />
                  } label="สองตัว-เรียงตามตัวเลข" />
               </Grid>

               <Grid item xs={12} spacing={1}>
               <TextField
               required
               
                value={this.state.format_three_input}
                onChange={(e) => { this.setState({format_three_input:e.target.value}); }}
               margin="dense"
               label="รูปแบบสามตัว"
               type="text"
               fullWidth
               // error={this.state.sender_idValidator}
               //helperText={this.state.sender_idValidator}
               />
               </Grid>

               <Grid item xs={12} spacing={1}>
                  <FormControlLabel control={
                  <SwitchControl color="primary"
                  checked={this.state.three_sort === '1' ? true : false}
                  onChange={(e) => { this.setState({three_sort:  e.target.checked ? '1' : '0' }) ; localStorage.setItem('three_sort', e.target.checked ? '1' : '0');   }}
                  />
                  } label="สามตัว-เรียงตามตัวเลข" />
               </Grid>


               <Grid item xs={12} spacing={1}>
               <TextField
               required
               
               value={this.state.format_one_input}
               onChange={(e) => { this.setState({format_one_input:e.target.value}); }}
               margin="dense"
               label="รูปแบบวิ่ง"
               type="text"
               fullWidth
               // error={this.state.sender_idValidator}
               //helperText={this.state.sender_idValidator}
               />
               </Grid>

               <Grid item xs={12} spacing={1}>
                  <FormControlLabel control={
                  <SwitchControl color="primary"
                  checked={this.state.one_sort === '1' ? true : false}
                  onChange={(e) => { this.setState({one_sort:  e.target.checked ? '1' : '0' }) ; localStorage.setItem('one_sort', e.target.checked ? '1' : '0');   }}
                  />
                  } label="วิ่ง-เรียงตามตัวเลข" />
               </Grid>

         </Grid>
      </DialogContent>

    

        
       
      <DialogActions>
          <Button  onClick={ () => { this.saveConfig();  }}   color="primary">
            บันทึกการตั้งค่า
          </Button>
          <Button  onClick={ () => { this.setState({ open_reports: false }); }}  color="primary">
            ปิด
          </Button>
        </DialogActions>

           
      </Dialog>

</Container>
    )

 }

}
  
export default withStyles(useStyles, { withTheme: true })(TicketDetail)