import React, { Component } from 'react';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import Icon from '@material-ui/core/Icon';
import Fab from '@material-ui/core/Fab';



const useStyles = theme => ({
  paper: {
    marginTop: theme.spacing(8),

  },
  div_number:{
    display: 'inline-block',
    Width:'400px',
    verticalAlign: 'top',
    overflow: 'auto',
    margin:'5px',
    //border: '1px solid #ddd;', //rgba(0, 120,205, 1)
  },
  scrollable:{
     overflow:'auto',
     width:'100%',
},
fab_save : {
   margin: '0px',
   top: '60px',
   left: 'auto',
   bottom: 'auto',
   right: '120px',
   position: 'fixed',
 },
 fab_sort : {
   margin: '0px',
   top: '60px',
   left: 'auto',
   bottom: 'auto',
   right: '40px',
   position: 'fixed',
 },
 fab_copy: {
  margin: '0px',
  top: '60px',
  left: 'auto',
  bottom: 'auto',
  right: '240px',
  position: 'fixed',
},
 

})



  class TextInput extends Component {
  //const classes = useStyles();
    
  constructor(props) {
    super(props) //since we are extending class Table so we have to use super in order to override Component class constructor
   
    
    this.state = { //state is by default an object
        lines:'',



    }
    this.parent=this.props.messages;
    this.iniData(false);
   
  
 }

  
 iniData=(sort)=>{
   //.slice(0) for clone only.
    var temp=this.parent.rows.slice(0);
    var datas;
     
    if(sort)
    {
      datas=temp.sort((a, b) => a.number.length.toString() + a.number.toString()  > b.number.length.toString() +  b.number.toString()  ? 1 : -1);
    }
    else
    {
      datas= temp;
    }
    
    this.state.lines='';
  //  console.log(datas);

var num_old='';

    for(var i=0;i<=datas.length-1;i++)
    {
        try{
        // console.log(row);
        if(datas[i].number.toString()!=='')
        {
         
          if(sort)
          {
            if(num_old!==datas[i].number.toString().length)
            {
             
              if(datas[i].number.toString().length===1){  this.state.lines += '\n#วิ่ง\n';}
              if(datas[i].number.toString().length===2){  this.state.lines += '\n#สองตัว\n';}
              if(datas[i].number.toString().length===3){  this.state.lines += '\n#สามตัว\n';}

              num_old=datas[i].number.toString().length;
            }
          }
            this.state.lines += datas[i].number.toString();
            this.state.lines += '=';
            this.state.lines += datas[i].top.toString();

            if( datas[i].bottom!=='')
            {
              this.state.lines += '*';
              this.state.lines += datas[i].bottom.toString();
            }

            if(datas[i].number.toString().length===3 &&  datas[i].bottom==='' )
            {
              this.state.lines += '*';
            }
            
            if (!( "option" in datas[i] )==0)
              {
                this.state.lines += '*';
                this.state.lines += datas[i].option.toString();
              }
          
        
            if(this.state.line!==''){this.state.lines += '\n';}
        }
        }catch{}
    }
 }


 
 SaveData=()=>{
   var out=[];
  var err='';
  var items =this.state.lines.split('\n');

  if(items.length===0 || this.state.lines==='')
  {
    alert('โปรดระบุข้อมูล');
    return false;
  }
  for(var i=0;i<items.length;i++)
  {
     // console.log(items[i]);
      

      if(items[i]!=='' && items[i].toString().includes('#')===false)
      {
        var err_sub='';

        var datas =items[i].toString().split('=');
      
       
        if(datas.length!==2)
        {
          err_sub+='รูปแบบไม่ถูกต้อง. ';
        }
        else{
          var num=datas[0];
          var inputs=datas[1].split('*');

          if(inputs.length>3)
          {
            err_sub+='รูปแบบไม่ถูกต้อง มีเครื่องหมาน * มากกว่าหนึ่ง. ';
          } 
          else{
            if(isNaN(num) || num.length>3 || num==='')
            {
              err_sub+='ตัวเลขไม่ถูกต้อง  จำกัด 3 ตัว และว่างไม่ได้. ';
            }

          }
          
          out.push({ number: num, top: inputs[0], bottom: inputs.length>1?inputs[1]:'' , option: inputs.length>2?inputs[2]:'' });

          
        }

        if(err_sub!==''){err+=err_sub + 'ข้อมูล : ' + items[i].toString() + '\n'}

      }
     
  }

  if(err!=='')
  {
    alert(err);
    return false;
  }
  this.props.onSaved(out);

 }
   
 handleText = event => {
 
 //alert(event.keyCode);
//187(=) /106(*) / 16(#)
   //96-105 is padnumber: 48-57: key number 0-9  37/39 left right arrow 38/40 up and down
   if (event.keyCode > 31 && event.keyCode !== 16  && event.keyCode !== 107 
    && event.keyCode !== 39 && event.keyCode !== 37 
    && event.keyCode !== 38 && event.keyCode !== 40
    && event.keyCode !== 187 && event.keyCode !== 106
    && (event.keyCode < 48 || event.keyCode > 57) && (event.keyCode < 96 || event.keyCode > 105)) {
    event.preventDefault();
    return false;
  }

}



copyToClipboard = (e) => {
  var textAreaRef = document.getElementById("textAreaRef");
  textAreaRef.select();
  document.execCommand('copy');
  // This is just personal preference.
  // I prefer to not show the whole text area selected.

   alert('copy text แล้ว.');
};

 render() { 
 
  //console.log(this.parent.rows);
    const { classes, theme } = this.props
  
   
//.sort((a, b) => a.itemM > b.itemM ? 1 : -1)



 
    return (
      <Container component="main"  >
       
       <div className={classes.scrollable}     >
            <h4>ท่านสามารถจัดการข้อมูล นำเข้า หรือส่งออก ผ่านกล่องข้อความ.</h4>
            <h4>Copy/paste ข้อมูล โดยเลือกข้อความที่ต้องการ และตำแหน่งวาง แล้วคลิกซ้ายที่กล่องข้อความ.</h4>

            <Grid item spacing={12} sm={12} xs={12}  >
                  <TextField
                  autoFocus
                  id="textAreaRef"
                  label="กรุณาระบุรายข้อมูล"
                  variant="outlined"
                  multiline
                  fullWidth
                  style = {{height:"calc(100vh - 220px)" }}
                  value={this.state.lines}
                  onChange={(e) => { this.setState({ lines:  e.target.value }); }}
                  onKeyDown={this.handleText}
                  rows={28}
                  />
               </Grid>
            
         </div>
         
         
       
        <Fab  title="จัดเรียงข้อความเพื่อส่งออก"  aria-label="sort" className={classes.fab_sort}   onClick={ () => { if(window.confirm('ท่านต้องการเรียงข้อมูลเพื่อส่งออกข้อความหรือไม่. หมายเหตุ เมื่อเรียงไม่สามารถคืนคาข้อมูลได้ กรณีไม่ต้องการบันทีก โดยไม่ต้องกดปุ่มบันทึก.')) { this.iniData(true);this.setState({});}      }  } >
        <Icon color="primary">sort</Icon>
        </Fab>
      
        <Fab  title="Copy"  aria-label="sort" className={classes.fab_copy}   onClick={ () => {  this.copyToClipboard();     }  } >
        <Icon color="primary">file_copy</Icon>
        </Fab>

       

        {window.locked===false   &&
              (
                <Fab title="บันทึกข้อมูล"   aria-label="print" className={classes.fab_save}  onClick={ () => { this.SaveData(); }  }  >
                <Icon color="primary">save</Icon>
                </Fab>

                )}
       


</Container>
    )

 }

}
  
export default withStyles(useStyles, { withTheme: true })(TextInput)